import { styled } from "@mui/material";

export const AddCardPaymentBox = styled("div")(({ theme }) => ({
  // padding: "2rem 2.5rem",

  "& .title": {
    fontWeight: 600,
    fontSize: "1.25rem",
    color: "#150200",
    padding: "1rem 1.25rem",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1);",
  },

  "& .details": {
    color: "#847F7F",
    fontWeight: 400,
    fontSize: "1rem",
    padding: "1rem 1.25rem 1.5rem",
    margin: 0,
  },

  "& .payStackContainer": {
    display: "flex",
    // alignItems: "baseline",
    justifyContent: "space-between",
    margin: "0 0 1.5rem",

    "& .logo": {
      marginTop: "0.25rem",
    },

    "& .amountDetails": {
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "column",
      textAlign: "left",
      color: "#847F7F",

      "& .pay": {
        marginLeft: "auto",
        fontSize: "1rem",
        fontWeeight: 400,

        "& span": {
          color: "#00AE97",
          fontWeight: 700,
          marginLeft: "0.2rem",
        },
      },
    },
  },

  "& .cardTypeContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 0 1.5rem",

    "& .cardName": {
      display: "flex",
      alignItems: "center",
      gap: "0 0.15rem",

      "& input[type=radio]": {
        accentColor: "#00AE97",
        height: "1.2rem",
        width: "1.2rem",
      },

      "& span": {
        color: "#150200",
        fontSize: "0.875rem",
        fontWeight: 400,
      },
    },

    "& .cardIcons": {
      display: "flex",
      alignItems: "center",
      gap: "0 0.3rem",

      "& img": {
        maxHeight: "20px",
      },
    },
  },

  "& .cardDetailsContainer": {
    border: "1px solid #C4C4C4",
    padding: "1rem 1.25rem",

    "& .title": {
      fontSize: "1.25rem",
      fontWeight: 500,
      color: "#847F7F",
      textAlign: "center",
      marginBottom: "1.75rem",
      boxShadow: "none",
    },

    "& .formContainer": {
      "& .pc_form__lebel": {
        color: "#150200",
        fontSize: "0.75rem",
        fontWeight: 600,

        "& span": {
          color: "#FF5349",
        },
      },

      "& .form-control": {
        padding: "1rem",

        "&:focus": {
          boxShadow: "none",
        },
      },

      "& .securityNote": {
        color: "#847F7F",
        fontSize: "0.675rem",
        fontWeight: 700,
        margin: "0 0 1.75rem",
        display: "flex",
        gap: "0 0.25rem",
        alignItems: "center",
      },

      "& .addCardBtn": {
        padding: "1.125rem 2rem",
        width: "100%",
        borderRadius: "5px",
        color: "#ffffff",
        background: "#00AE97",
        fontWeight: 600,
      },
    },
  },

  //   Styles for screen of width 767.9px and below
  "@media (max-width:991.9px)": {},
}));

export enum RewardTypes {
  Points = "Points",
  Coupon = "Coupon",
  FreeItem = "FreeItem",
  Discount = "Discount",
}

export enum RewardRuleAttributeNames {
  CartItem = "CartItem",
  CartItemValue = "CartItemValue",
  CartItemQuantity = "CartItemQuantity",
  CartTotalValue = "CartTotalValue",
  ConsumerProfileAudience = "ConsumerProfileAudience",
  ConsumerProfileDateOfBirth = "ConsumerProfileDateOfBirth",
  RewardStartDate = "RewardStartDate",
  RewardEndDate = "RewardEndDate",
}

export enum RewardRuleAttributeValueDescriptions {
  Percentage = "Percentage",
  Sku = "Sku",
  Quantity = "Quantity",
  Monetary = "Monetary",
  ScheduleDate = "ScheduleDate",
}

export enum RewardRuleEffects {
  AddDiscount = "AddDiscount",
  AddPoints = "AddPoints",
  AddFreeItem = "AddFreeItem",
  CanApplyNonExpiredReward = "CanApplyNonExpiredReward",
  CanApplyLiveReward = "CanApplyLiveReward",
}

export enum RewardRuleAttributeCriteria {
  EqualTo = "EqualTo",
  NotEqualTo = "NotEqualTo",
  GreaterThanOrEqualTo = "GreaterThanOrEqualTo",
  GreaterThan = "GreaterThan",
  LessThan = "LessThan",
  LessThanOrEqualTo = "LessThanOrEqualTo",
  In = "In",
  NotIn = "NotIn",
}

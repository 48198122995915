import { styled, Button } from "@mui/material";

export const ButtonBox = styled(Button)(({ theme }) => ({
  height: "50px !important",
  borderRadius: "5px",
  padding: "0.9rem 1.5rem",
  width: "100%",
  textAlign: "center", // Typo: Change "ecnter" to "center"
  transition: "0.3s all ease-in-out",
  fontWeight: 500,
  fontSize: "1rem",
  borderWidth: "1px",
  borderStyle: "solid",
  textTransform: "capitalize"
}));

import { styled } from "@mui/material";

export const PaymentSuccessBox = styled("div")(({ theme }) => ({
  padding: "2.5rem 3rem",
  background: "#ffffff",

  "& .paymentContainer": {
    margin: "1.5rem 0",
    padding: "1.25rem",
    border: "0.5px solid #C4C4C4",
    borderRadius: "6px",

    "& svg": {
      display: "block",
      margin: "0 auto 1rem",
    },

    "& .title": {
      color: "#00AE97",
      fontSize: "1.25rem",
      marginBottom: "0.5rem",
    },

    "& .details": {
      color: "#847F7F",
      fontSize: "1rem",
      margin: "0 0.8rem 0",
    },

    "& .title, .details, .detailTitle": {
      textAlign: "center",
      fontFamily: "Raleway",
      fontWeight: 400,
    },

    "& .infoDetails": {
      color: "#847F7F",
      fontSize: "0.8rem",
      fontWeight: 400,
      fontFamily: "Poppins",
    },

    "& .detailTitle": {
      color: "#847F7F",
      fontSize: "1.15rem",
      fontWeight: 700,
      margin: "0.5rem 0 2.5rem",
    },


    "& .sectionTitle": {
      color: "#000000",
      fontSize: "1rem",
      fontWeight: 600,
      marginBottom: "0.65rem",
    },

    "& .transactDetailsGroup": {
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem 0",
      margin: 0,
      padding: 0,

      "& .detailsList": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "1rem",
        color: "#847F7F",
        fontFamily: "Poppins",
        fontWeight: 400,

        "& .name": {
          // fontWeight: 500,
        },

        "& .value": {
          fontWeight: 400,
          
        },
      },
    },
  },

  // Screen width 991.9px
  "@media (max-width:991.9px)": {
    padding: "2rem 2.5rem",
  },

  //   Styles for screen of width 767.9px and below
  "@media (max-width:767.9px)": {
    padding: "1.5rem",

    "& .paymentContainer": {
      margin: "1.5rem 0",
      padding: "1rem",

      "& svg": {
        width: "65px",
      },

      "& .title": {
        fontSize: "1rem",
      },

      "& .details": {
        fontSize: "0.875rem",
      },

      "& .transactDetailsGroup": {
        "& .detailsList": {
          fontSize: "0.875rem",
        },
      },
    },
  },
}));

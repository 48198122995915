import * as React from "react";
import { HomeBox } from "./home.styles";
import {
  HandClickIcon,
  CartValueIcon,
  ConversionIcon,
  GatewayIcon,
} from "../../assets/svg";
import { Footer, Navbar } from "./components";
import TopMobileImg from "../../assets/images/top-mobile-mock.svg";
import BottomMobileImg from "../../assets/images/bottom-mobile-mock.svg";
import DesktopImg from "../../assets/images/desktop-mock.svg";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/swiper-bundle.css";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

export const LandingPage = () => {
  return (
    <>
      <HomeBox>
        <Navbar />
        <header className="headerSection">
          <div className="container">
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper: any) => console.log(swiper)}
              modules={[Autoplay, Navigation, Pagination]}
              autoplay={{
                delay: 5000,
              }}
              loop={true}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              pagination={{
                type: "bullets",
                clickable: true,
              }}
            >
              <SwiperSlide>
                <div className="row">
                  <div className="col-lg-6 d-flex align-items-center">
                    <div className="textHolder">
                      <h2 className="title">
                        Maximise conversions, reduce cart abandonment, and boost
                        cart value.
                      </h2>
                      <p className="details">
                        Start rewarding customers and accepting card, gift card,
                        coupon or points based payment options in-app using our
                        mobile checkout SDK
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mobileImgHolder">
                      <img src={TopMobileImg} className="img-fluid topImg" />
                      <img
                        src={BottomMobileImg}
                        className="img-fluid bottonImg"
                      />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="row">
                  <div className="col-lg-6 d-flex align-items-center">
                    <div className="textHolder">
                      <h2 className="title">
                        Enable delightful ways to pay, personalise checkout
                        rewards
                      </h2>
                      <p className="details">
                        Start rewarding customers and accepting card, gift card,
                        coupon or points based payment options using our web
                        checkout SDK
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mt-5 mt-lg-0 ms-lg-auto ">
                      <img src={DesktopImg} className="img-fluid" />
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <div className="navigatorHolder">
                <div className="swiper-pagination"></div>
              </div>
            </Swiper>
          </div>
        </header>

        <section className="offeringSection">
          <div className="container">
            <div className="row mb-5">
              <div className="col-xl-7 col-lg-8 col-md-10 col-12">
                <h4 className="title">
                  A seamless checkout solution built to enable accelerated
                  business growth
                </h4>
                <p className="details">
                  Checkout solution integrated with your promotions and loyalty
                  offerings to incentivise your customers and provide more ways
                  to pay.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 col-6">
                <div className="offerCard">
                  <div className="iconHolder">
                    <HandClickIcon />
                  </div>
                  <h6 className="name">Optimised checkout flow</h6>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className="offerCard">
                  <div className="iconHolder">
                    <CartValueIcon />
                  </div>
                  <h6 className="name">Increase cart value</h6>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className="offerCard">
                  <div className="iconHolder">
                    <ConversionIcon />
                  </div>
                  <h6 className="name">Higher conversion</h6>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className="offerCard" style={{ border: "none" }}>
                  <div className="iconHolder">
                    <GatewayIcon />
                  </div>
                  <h6 className="name">Multiple payment gateways</h6>
                </div>
              </div>
            </div>
          </div>
        </section>
      </HomeBox>
      <Footer />
    </>
  );
};

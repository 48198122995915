import { styled } from "@mui/material";

export const CartCardBox = styled("div")(({ theme }) => ({
  "& .cartCard": {
    display: "grid",
    gridTemplateColumns: "80px calc(100% - 170px) 90px",
    // gap: "0.875rem",
    paddingBottom: "1.25rem",
    borderBottom: "1px solid #e0e0e0 !important",

    // "&:last-child": {
    //   paddingBottom: 0,
    //   borderBottom: "none",
    // },
  },

  "& .imgHolder": {
    backgroundColor: "#e8effa",
    borderRadius: "4px",
    width: "80px",
    height: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  "& .detailSection": {
    margin: "0 0.75rem",

    "& .text": {
      color: "#000000",
      fontSize: "0.75rem",
      fontWeight: 400,
      margin: "0 0.5rem 0.625rem 0",
      lineHeight: 1.65,
    },
  },

  "& .quantity": {
    color: "#00AE97",
    fontSize: "0.625rem !important",
    fontWeight: 400,
    marginBottom: "0.15rem"
  },

  "& .code": {
    color: "#000000",
    fontSize: "0.625rem",
    margin: "0.2rem 0",
  },

  "& .rewardSection": {
    display: "flex",
    alignItems: "center",
    fontWeight: 400,
    marginTop: "0.5rem",

    "& .rewardBadge": {
      background: "#00AE97",
      borderRadius: "5px",
      padding: "0.25rem 0.375rem",
      color: "#ffffff",
      fontSize: "0.75rem",
    },

    "& .value": {
      fontSize: "0.75rem",
    },
  },

  "&  .priceContainer": {
    display: "flex",
    textAlign: "end",
    flexDirection: "column",
    justifyContent: "space-between",
    fontSize: "0.875rem",
    fontWeight: 600,

    "& .oldPrice": {
      color: "#847F7F",
      textDecoration: "line-through",
    },

    "& .price": {
      color: "#000000",
    },
  },

  "& .rewardAppliedBadge": {
    padding: "0.2rem 0.4rem",
    border: "1px solid #00AE97",
    borderRadius: "4px",
    display: "inline-flex",
    alignItems: "center",
    gap: "0 0.2rem",
    fontSize: "0.625rem",
    fontWeight: 400,
    color: "#00AE97",
  },

  //   Styles for screen of width 767.9px and below
  "@media (max-width:991.9px)": {
    "& .cartCard": {
      gridTemplateColumns: "70px calc(100% - 150px) 80px",
      // gap: "0.5rem",
      paddingBottom: "1rem",
    },

    "& .imgHolder": {
      width: "70px",
      height: "70px",
    },

    "& .detailSection": {
      margin: "0 0.5rem",

      "& .text": {
        margin: "0 0.25rem 0.rem 0",
        lineHeight: 1.5,
      },
    },

    "& .quantity": {
      fontSize: "0.85rem",
    },

    "& .price": {
      fontSize: "0.9rem",
    },
  },
}));

import * as React from "react";
import { OrderBox } from "./OrderSummary.style";
import { PCButton, ToastMessage, TOASTER_TYPE } from "../../../../components";
import {
  calculateTotalPrice,
  convertPriceToNaira,
} from "../../../../utils/helper";
import { checkoutToPay } from "../../../../services/api";
import { NumericFormat } from "react-number-format";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { RewardRuleEffects } from "../../../../types/index";

interface IOrderProps {
  totalCost: number | any;
  isCardSelected: boolean;
  pointData: any;
  rewardData: any;
  cardDetails: any;
  gateWayDetails: any;
  updatedCartTotal: (a: number) => any;
  rawCartTotal: number;
}

export const OrderSummary: React.FC<IOrderProps> = ({
  totalCost,
  isCardSelected,
  pointData,
  rewardData,
  cardDetails,
  gateWayDetails,
  updatedCartTotal,
  rawCartTotal,
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [updatedCartItemTotal, setUpdatedCartItemTotal] =
    React.useState<any>(null);

  React.useEffect(() => {
    setUpdatedCartItemTotal(handleUpdateCartTotal());
  }, [pointData.value]);

  const handleUpdateCartTotal = () => {
    const calculatedTotal: number = calculateTotalPrice(
      totalCost,
      pointData.value,
      rewardData.onCartTotal.price
    );

    // Updating calculated cart total amount in Parent (Wallet)
    updatedCartTotal(calculatedTotal);

    return calculatedTotal;
  };

  const handleCheckoutToPay = async (amount: number) => {
    setIsLoading(true);
    const sessionUniqueReferenceNumber = JSON.parse(
      localStorage.getItem("session_data") as string
    )?.uniqueReferenceNumber;

    const pointPayload = {
      loyaltyProgrammeUniqueReferenceNumber:
        pointData?.userPointDetail?.loyaltyProgrammeUniqueReferenceNumber,
      loyaltyMemberUniqueReferenceNumber:
        pointData?.userPointDetail?.loyaltyMemberUniqueReferenceNumber,
      points: parseInt(pointData?.value),
    };

    const cardPayload = {
      authorizationCode: cardDetails?.authorizationCode,
      customerId: cardDetails?.gatewayCustomerId,
      amount: amount, // , amount in kobo
      emailAddress: cardDetails?.emailAddress,
      currency: gateWayDetails.paymentGatewayType === "STRIPE" ? "GBP" : "NGN", // make GBP for stripe
      paymentMethod: "card",
      cardTokenUniqueReferenceNumber: cardDetails?.uniqueReferenceNumber,
      paymentUniqueReferenceNumber: uuidv4(), // generate for everytime payment is processed
      paymentItemUniqueReferenceNumber: sessionUniqueReferenceNumber, // attached to a session
    };

    const rewardPayload = {
      uniqueReferenceNumber: rewardData?.selectedReward?.uniqueReferenceNumber,
      discountAmountApplied: rewardData?.selectedReward?.discountAmountApplied, // converted to kobo
      promotionUniqueReferenceNumber:
        rewardData?.selectedReward?.promotionUniqueReferenceNumber || "hgfhd",
      loyaltyProgrammeTierUniqueReferenceNumber: "fhdj",
    };

    let payload = {
      pointsRedemption: pointData.value >= 1 ? pointPayload : undefined,
      recurrentBilling: isCardSelected ? cardPayload : undefined,
      rewardRedemption: rewardData.isRewardSelected ? rewardPayload : undefined,
    };

    // Intuitively detect items not selected to pay(checkout)
    if (payload.pointsRedemption === undefined) {
      delete payload.pointsRedemption;
    }

    if (payload.recurrentBilling === undefined) {
      delete payload.recurrentBilling;
    }

    if (payload.rewardRedemption === undefined) {
      delete payload.rewardRedemption;
    }

    await checkoutToPay(payload)
      .then((res: any) => {
        if (res) {
          setIsLoading(false);
          // console.log(res);
          if (res.data.status) {
            localStorage.setItem("c_status", "true");
            navigate("/payment-success", {
              replace: true,
              state: {
                paymentDetails: res.data.data,
                gateWayDetails,
                rawCartTotal,
              },
            });
          }
        }
      })
      .catch((err: any) => {
        console.log(err, "Checkout error");
        setIsLoading(false);
      });
  };

  const renderButton = (amount: number) => {
    amount = amount < 0 ? 0 : amount;

    let disableStatus: boolean = true;
    const total = calculateTotalPrice(
      totalCost,
      pointData.value,
      rewardData.onCartTotal.price
    );

    if (isCardSelected && cardDetails) {
      disableStatus = false;
    } else if (pointData.value !== "" && total <= 0) {
      disableStatus = false;
    } else {
    }

    return (
      <PCButton
        text={`Pay ${gateWayDetails?.currencySymbol}${convertPriceToNaira(
          amount
        )}`}
        isDisabled={disableStatus}
        click={() => handleCheckoutToPay(amount)}
        loading={isLoading}
      />
    );
  };

  return (
    <OrderBox>
      <ul>
        {pointData.check && (
          <li>
            <div className="key">Points value applied</div>
            <div className="value">
              {pointData.check && pointData.value ? (
                <NumericFormat
                  value={pointData.value}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={gateWayDetails?.currencySymbol}
                />
              ) : (
                `${gateWayDetails?.currencySymbol}0`
              )}
            </div>
          </li>
        )}

        {rewardData.onCartTotal.price !== 0 && (
          <li>
            {/* <div className="key">
              Reward applied for {rewardData.onCartTotal.percentOff}% off{" "}
            </div> */}
            <div className="rewardAppliedBadge">
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 3.04688V4.6875H9.14063C9.46511 4.6875 9.78231 4.59128 10.0521 4.41101C10.3219 4.23073 10.5322 3.9745 10.6564 3.67472C10.7805 3.37493 10.813 3.04506 10.7497 2.72681C10.6864 2.40856 10.5302 2.11622 10.3007 1.88678C10.0713 1.65733 9.77895 1.50108 9.4607 1.43777C9.14245 1.37447 8.81257 1.40696 8.51279 1.53114C8.213 1.65531 7.95677 1.86559 7.7765 2.13539C7.59622 2.40519 7.5 2.72239 7.5 3.04688V3.04688ZM7.5 3.04688V4.6875H5.85938C5.53489 4.6875 5.21769 4.59128 4.94789 4.41101C4.67809 4.23073 4.46781 3.9745 4.34364 3.67472C4.21946 3.37493 4.18697 3.04506 4.25027 2.72681C4.31358 2.40856 4.46983 2.11622 4.69928 1.88678C4.92872 1.65733 5.22106 1.50108 5.53931 1.43777C5.85756 1.37447 6.18743 1.40696 6.48722 1.53114C6.787 1.65531 7.04323 1.86559 7.22351 2.13539C7.40378 2.40519 7.5 2.72239 7.5 3.04688V3.04688Z"
                  stroke="#00AE97"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M12.1875 4.6875H2.8125C2.29473 4.6875 1.875 5.10723 1.875 5.625V7.03125C1.875 7.54902 2.29473 7.96875 2.8125 7.96875H12.1875C12.7053 7.96875 13.125 7.54902 13.125 7.03125V5.625C13.125 5.10723 12.7053 4.6875 12.1875 4.6875Z"
                  stroke="#00AE97"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.1875 7.96875V12.1875C12.1875 12.5605 12.0393 12.9181 11.7756 13.1819C11.5119 13.4456 11.1542 13.5937 10.7812 13.5938H4.21875C3.84579 13.5937 3.4881 13.4456 3.22438 13.1819C2.96066 12.9181 2.8125 12.5605 2.8125 12.1875V7.96875M7.5 4.6875V13.5938"
                  stroke="#00AE97"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <> SaveNMore - [ {rewardData.onCartTotal.percentOff}% off ] </>
            </div>

            <div className="value">
              <NumericFormat
                value={convertPriceToNaira(rewardData.onCartTotal.price)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={gateWayDetails?.currencySymbol}
              />
              {/* &#8358;{reward.price} */}
            </div>
          </li>
        )}
        {/* <li>
          <div className="key">Reward applied for buy 2 get 1 free </div>
          <div className="value">NGN 10,000</div>
        </li> */}
        <li className="mt-3">
          <div className="key total">Total</div>
          <div className="value total">
            <NumericFormat
              value={convertPriceToNaira(handleUpdateCartTotal())}
              displayType={"text"}
              thousandSeparator={true}
              prefix={gateWayDetails?.currencySymbol}
            />
          </div>
        </li>
      </ul>

      <>{renderButton(handleUpdateCartTotal())}</>
    </OrderBox>
  );
};

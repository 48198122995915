import { styled } from "@mui/material";

export const WalletCheckoutBox = styled("div")(({ theme }) => ({
  margin: "0 auto 2rem",
  position: "relative",

  "& .header": {
    width: "70%",

    "& .title": {
      color: "#150200",
      fontSize: "1.25rem",
      fontWeight: 500,
    },

    "&  .details": {
      fontSize: "1rem",
      color: "#847F7F",
      fontWeight: 400,
    },
  },

  "& .info": {
    color: "#847F7F",
    fontWeight: 400,
    fontSize: "0.625rem",
  },

  "& .addPaymentContainer": {
    display: "inline-flex",
    alignItems: "center",
    gap: "0 0.4rem",
    color: "#00AE97",
    cursor: "pointer",
    marginBottom: "1.5rem",

    "& .text": {
      fontSize: "0.75rem",
      fontWeight: 500,
      fontFamily: "Poppins",
    },
  },

  "& .iframeContainer": {
    position: "fixed",
    zIndex: "20",
    top: 0,
    right: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(229, 229, 229, 0.38)",
    backdropFilter: "blur(2px)",
  },

  "& .closeLoginIframe": {},

  //   Styles for screen of width 767.9px and below
  "@media (max-width:767.9px)": {
    "& .header": {
      width: "98%",
      textAlign: "center",

      "& .title": {
        fontSize: "1.05rem",
      },

      "&  .details": {
        fontSize: "0.875rem",
        margin: "0 0.25rem"
      },
    },
  },
}));

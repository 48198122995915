import { styled } from "@mui/material";

export const HomeBox = styled("main")(({ theme }) => ({
  "& .navSection": {
    background: "#F7F7F4",
    padding: "2.5rem 0 1.5rem",
  },

  "& .headerSection": {
    padding: "5rem 0 7rem",
    background: "#F7F7F4",
    position: "relative",

    "& .container": {
      position: "unset",
    },

    "& .textHolder": {
      color: "#150200",
      marginRight: "1rem",

      "& .title": {
        fontFamily: "Poppins",
        fontSize: "2.75rem",
        fontWeight: 600,
      },

      "& .details": {
        fontFamily: "Montserrat",
        fontSize: "1.125rem",
        fontWeight: 400,
        lineHeight: "1.875rem",
        letterSpacing: "0.02338rem",
      },
    },

    "& .mobileImgHolder": {
      position: "relative",
      //   top: "-20%",
      height: "670px",

      "& img": {
        position: "absolute",
      },

      "& .topImg": {
        maxWidth: "80%",
        left: "6%",
        top: "10%",
        zIndex: 1,
      },

      "& .bottonImg": {
        right: 0,
        maxWidth: "66%",
      },
    },

    "& .swiper": {
      paddingBottom: "6rem",

      "& .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal":
        {
          bottom: "-1.25%",
        },

      "& .swiper-pagination-bullet": {
        width: "20px",
        height: "7px",
        borderRadius: "14px",
        transition: ".5s all ease-in-out",
        background: "#ff5349",
        opacity: 0.2,
      },

      "& .swiper-pagination-bullet-active": {
        width: "40px",
        opacity: 1,
      },
    },

    "& .navigatorHolder": {
      display: "flex",
      justifyContent: "center",
      gap: "1rem",
      marginBottom: "-4rem",

      "& .swiper-button-next, .swiper-button-prev": {
        borderRadius: "50%",
        cursor: "pointer",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(255,83,73, 0.25)",
        width: "2rem",
        height: "2rem",
        transition: "all .4s ease-in-out",

        "& svg": {
          stroke: "rgb(255,83,73)",
          width: "1.5rem",
          height: "1.5rem",
        },

        "&:hover": {
          background: "rgb(255,83,73)",

          "& svg": {
            stroke: "#ffffff",
          },
        },
      },
    },
  },

  "& .offeringSection": {
    background: "#ffffff",
    padding: "5rem 0 8rem",

    "& .title": {
      color: "#150200",
      fontFamily: "Poppins",
      fontSize: "2.375rem",
      fontWeight: 600,
    },

    "& .details": {
      color: "#150200",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "Montserrat",
      fontSize: "1.125rem",
      fontWeight: 400,
      lineHeight: "1.875rem",
      letterSpacing: "0.02338rem",
      marginBottom: "2rem",
    },

    "& .offerCard": {
      textAlign: "center",
      borderRight: "1px dashed #969BA2",

      "& .iconHolder": {
        marginBottom: "1rem",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
      },

      "& .name": {
        color: "#150200",
        fontFamily: "Poppins",
        fontSize: "1.125rem",
        fontWeight: 400,
        lineHeight: "125%",
      },
    },
  },

  // Screen width 991.9px
  "@media (max-width:1199.9px)": {
    "& .navSection": {
      padding: "2rem 0 1.25rem",
    },

    "& .headerSection": {
      padding: "5rem 0 7rem",
      background: "#F7F7F4",

      "& .textHolder": {
        marginRight: "0",

        "& .title": {
          fontSize: "2.5rem",
        },

        "& .details": {
          fontSize: "1.15rem",
        },
      },

      "& .mobileImgHolder": {
        // top: "-10%",
        height: "490px",
      },
    },

    "& .offeringSection": {
      padding: "3.5rem 0 5rem",

      "& .title": {
        fontSize: "2.25rem",
      },

      "& .details": {
        fontSize: "1.05rem",
      },

      "& .offerCard": {
        padding: "0 1.25rem",

        "& .iconHolder": {
          "& svg": {
            width: "50px",
          },
        },

        "& .name": {
          fontSize: "0.9rem",
        },
      },
    },
  },

  //   Styles for screen of width 767.9px and below
  "@media (max-width:767.9px)": {
    "& .navSection": {
      padding: "2rem 0 1.25rem",

      "& img": {
        width: "150px",
      },
    },

    "& .headerSection": {
      padding: "4rem 0",

      "& .textHolder": {
        "& .title": {
          fontSize: "2rem",
        },

        "& .details": {
          fontSize: "1rem",
        },
      },

      "& .mobileImgHolder": {
        // top: "-10%",
        // height: "unset",
      },

      "& .swiper": {
        "& .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal":
          {
            bottom: "-0.75%",
          },

        "& .swiper-pagination-bullet": {
          width: "18px",
          height: "5px",
          borderRadius: "10px",
        },

        "& .swiper-pagination-bullet-active": {
          width: "35px",
        },
      },
    },

    "& .offeringSection": {
      padding: "3.5rem 0 2rem",

      "& .title": {
        fontSize: "1.75rem",
      },

      "& .details": {
        fontSize: "0.95rem",
      },

      "& .offerCard": {
        padding: "0 1rem",
        border: "none",
        marginBottom: "2.5rem",

        "& .iconHolder": {
          "& svg": {
            width: "40px",
          },
        },

        "& .name": {
          fontSize: "0.875rem",
        },
      },
    },
  },

  "@media (max-width:567.9px)": {
    "& .headerSection": {
      "& .textHolder": {
        textAlign: "center",
      },
    },

    "& .offeringSection": {
      "& .title, .details": {
        textAlign: "center",
      },
    },
  },
}));

import * as React from "react";
import { styled } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

export const FooterBox = styled("footer")(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  width: "100%",
  height: "0px",

  "& .textHolder": {
    textAlign: "center",
    padding: "2rem 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& h6": {
      fontSize: "0.875rem",
      fontWeight: 600,
      color: "#847F7F",
      marginLeft: "0.25rem",
    },
  },
}));

export const Footer = () => {
  const { pathname } = useLocation();
  const { accounts } = useMsal();

  return (
    <>
      {pathname !== "/b2cLogin" &&
        pathname !== "/loginComplete" &&
        accounts &&
        accounts.length >= 1 && (
          <FooterBox>
            <div className="textHolder">
              <svg
                width="11"
                height="13"
                viewBox="0 0 11 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 5.5H9.4375V2C9.4375 0.895313 8.54219 0 7.4375 0H3.5625C2.45781 0 1.5625 0.895313 1.5625 2V5.5H0.5C0.223437 5.5 0 5.72344 0 6V12C0 12.2766 0.223437 12.5 0.5 12.5H10.5C10.7766 12.5 11 12.2766 11 12V6C11 5.72344 10.7766 5.5 10.5 5.5ZM5.9375 9.20312V10.0312C5.9375 10.1 5.88125 10.1562 5.8125 10.1562H5.1875C5.11875 10.1562 5.0625 10.1 5.0625 10.0312V9.20312C4.93352 9.11052 4.83725 8.97938 4.78755 8.82859C4.73784 8.67779 4.73727 8.51511 4.78591 8.36396C4.83455 8.21281 4.92989 8.081 5.05821 7.98749C5.18654 7.89398 5.34122 7.8436 5.5 7.8436C5.65878 7.8436 5.81346 7.89398 5.94179 7.98749C6.07011 8.081 6.16545 8.21281 6.21409 8.36396C6.26273 8.51511 6.26216 8.67779 6.21245 8.82859C6.16275 8.97938 6.06648 9.11052 5.9375 9.20312ZM8.3125 5.5H2.6875V2C2.6875 1.51719 3.07969 1.125 3.5625 1.125H7.4375C7.92031 1.125 8.3125 1.51719 8.3125 2V5.5Z"
                  fill="#FF5349"
                />
              </svg>

              <h6 className="mb-0">Secured by PAYCURRA</h6>
            </div>
          </FooterBox>
        )}
    </>
  );
};

import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { ButtonBox } from "./styles";

interface IButtonProps {
  withRoute?: boolean;
  link?: string;
  text: string;
  click?: any;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  loading?: boolean;
  variant?: "Primary" | "PrimaryOutline" | "Secondary";
}

const btnPrimary = {
  backgroundColor: (theme: any) => theme.palette.primary.main,
  color: "#ffffff",
  borderColor: (theme: any) => theme.palette.primary.main,

  "&:hover": {
    backgroundColor: (theme: any) => theme.palette.primary.light,
  },

  "&:disabled": {
    backgroundColor: "#C4C4C4",
    borderColor: "#C4C4C4",
  },
};

const btnOutline = {
  backgroundColor: "transparent",
  color: (theme: any) => theme.palette.primary.main,
  borderColor: (theme: any) => theme.palette.primary.main,
  borderWidth: "1.5px",

  "&:hover": {
    color: "#ffffff",
    backgroundColor: (theme: any) => theme.palette.primary.light,
  },

  "&:disabled": {
    backgroundColor: "#C4C4C4",
  },
};

const btnSecondary = {};

export const PCButton: React.FC<IButtonProps> = ({
  withRoute = false,
  link,
  text,
  click,
  isDisabled,
  isFullWidth = true,
  loading,
  variant = "Primary",
}) => {
  const navigate = useNavigate();

  return (
    <ButtonBox
      // style={{
      //   width: isFullWidth ? "100%" : "auto",
      // }}
      disabled={isDisabled}
      onClick={() => {
        if (link && withRoute) navigate(link);
        else click();
      }}
      sx={
        variant === "Primary"
          ? btnPrimary
          : variant === "PrimaryOutline"
          ? btnOutline
          : variant === "Secondary"
          ? btnSecondary
          : undefined
      }
    >
      {text}

      {loading && (
        <CircularProgress
          sx={{
            width: "27px !important",
            height: "27px !important",
            color: "#ffffff",
          }}
          className="ms-2"
        />
      )}
    </ButtonBox>
  );
};

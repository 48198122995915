import * as React from "react";
import { ButtonBase } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { msalConfig } from "../../config";
import { useLocation } from "react-router-dom";
import { InteractionStatus } from "@azure/msal-browser";
import { NavBox } from "./navBar.styles";

export const NavBar = () => {
  const { instance, accounts, inProgress } = useMsal();
  const { pathname } = useLocation();

  React.useEffect(() => {
    console.log("Msal progress check", inProgress);
  }, [inProgress]);

  const closeIframe = async () => {
    localStorage.clear();
    sessionStorage.clear();
    const logoutRequest = {
      accounts: instance.getAccountByHomeId(accounts[0].homeAccountId),
      postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
    };

    // Handle msal loogout
    instance.logoutRedirect(logoutRequest).then((res) => {
      console.log("Log out complete", res);
    });
  };

  return (
    <>
      {pathname !== "/b2cLogin" &&
        pathname !== "/loginComplete" &&
        accounts &&
        accounts.length >= 1 && (
          <NavBox className="container">
            <ButtonBase className="iconHolder" onClick={closeIframe}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.5">
                  <path
                    d="M12 13.4L14.9 16.3C15.0833 16.4833 15.3167 16.575 15.6 16.575C15.8833 16.575 16.1167 16.4833 16.3 16.3C16.4833 16.1167 16.575 15.8833 16.575 15.6C16.575 15.3167 16.4833 15.0833 16.3 14.9L13.4 12L16.3 9.1C16.4833 8.91667 16.575 8.68333 16.575 8.4C16.575 8.11667 16.4833 7.88333 16.3 7.7C16.1167 7.51667 15.8833 7.425 15.6 7.425C15.3167 7.425 15.0833 7.51667 14.9 7.7L12 10.6L9.1 7.7C8.91667 7.51667 8.68333 7.425 8.4 7.425C8.11667 7.425 7.88333 7.51667 7.7 7.7C7.51667 7.88333 7.425 8.11667 7.425 8.4C7.425 8.68333 7.51667 8.91667 7.7 9.1L10.6 12L7.7 14.9C7.51667 15.0833 7.425 15.3167 7.425 15.6C7.425 15.8833 7.51667 16.1167 7.7 16.3C7.88333 16.4833 8.11667 16.575 8.4 16.575C8.68333 16.575 8.91667 16.4833 9.1 16.3L12 13.4ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22Z"
                    fill="white"
                  />
                  <path
                    d="M13.2474 12L16.739 8.50842C16.9047 8.343 16.9979 8.11853 16.9981 7.88439C16.9983 7.65025 16.9055 7.42562 16.7401 7.25991C16.5747 7.0942 16.3502 7.00099 16.1161 7.00078C15.8819 7.00057 15.6573 7.09339 15.4916 7.2588L12 10.7504L8.50842 7.2588C8.34271 7.09309 8.11796 7 7.88361 7C7.64926 7 7.42451 7.09309 7.2588 7.2588C7.09309 7.42451 7 7.64926 7 7.88361C7 8.11796 7.09309 8.34271 7.2588 8.50842L10.7504 12L7.2588 15.4916C7.09309 15.6573 7 15.882 7 16.1164C7 16.3507 7.09309 16.5755 7.2588 16.7412C7.42451 16.9069 7.64926 17 7.88361 17C8.11796 17 8.34271 16.9069 8.50842 16.7412L12 13.2496L15.4916 16.7412C15.6573 16.9069 15.882 17 16.1164 17C16.3507 17 16.5755 16.9069 16.7412 16.7412C16.9069 16.5755 17 16.3507 17 16.1164C17 15.882 16.9069 15.6573 16.7412 15.4916L13.2474 12Z"
                    fill="#150200"
                  />
                </g>
              </svg>
            </ButtonBase>
          </NavBox>
        )}
    </>
  );
};

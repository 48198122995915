import * as React from "react";
import { EarnedPointBox } from "./EarnedPoint.style";
import { RewardRuleEffects } from "../../../../types";

interface IProps {
  cartItemEarnable: any[];
  cartTotalEarnable: any[];
}

export const EarnedPoint: React.FC<IProps> = ({
  cartItemEarnable,
  cartTotalEarnable,
}) => {
  console.log("cartItemEarnable", cartItemEarnable);
  console.log("cartTotalEarnable", cartTotalEarnable);

  return (
    <EarnedPointBox>
      {cartItemEarnable.map((reward: any, id: number) => (
        <div className="earnedPoint" key={id}>
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.85547 4.21867H8.14453V7.49992H12.9492C13.1202 7.49992 13.2841 7.43201 13.405 7.31114C13.5258 7.19026 13.5938 7.02633 13.5938 6.85538V4.8632C13.5938 4.69226 13.5258 4.52832 13.405 4.40744C13.2841 4.28657 13.1202 4.21867 12.9492 4.21867H11.1967C11.434 3.72988 11.4893 3.17249 11.3526 2.64661C11.2159 2.12074 10.8961 1.66083 10.4508 1.34951C10.0055 1.0382 9.46373 0.895859 8.92289 0.948072C8.38205 1.00029 7.87755 1.24363 7.5 1.63439C7.1218 1.24577 6.61773 1.00431 6.07787 0.953163C5.53802 0.902018 4.99757 1.04452 4.55312 1.3552C4.10867 1.66588 3.78919 2.1245 3.65177 2.64907C3.51436 3.17364 3.56797 3.72999 3.80303 4.21867H2.05078C1.87984 4.21867 1.7159 4.28657 1.59503 4.40744C1.47416 4.52832 1.40625 4.69226 1.40625 4.8632V6.85538C1.40625 7.02633 1.47416 7.19026 1.59503 7.31114C1.65488 7.37099 1.72593 7.41846 1.80413 7.45085C1.88233 7.48324 1.96614 7.49992 2.05078 7.49992H6.85547V4.21867ZM8.14453 3.22257C8.14453 3.02556 8.20295 2.83298 8.3124 2.66917C8.42186 2.50537 8.57742 2.37769 8.75944 2.3023C8.94145 2.22691 9.14173 2.20718 9.33495 2.24562C9.52818 2.28405 9.70566 2.37892 9.84497 2.51823C9.98428 2.65753 10.0791 2.83502 10.1176 3.02824C10.156 3.22147 10.1363 3.42175 10.0609 3.60376C9.9855 3.78577 9.85783 3.94134 9.69403 4.05079C9.53022 4.16025 9.33763 4.21867 9.14062 4.21867H8.14453V3.22257ZM4.86328 3.22257C4.86328 2.95839 4.96823 2.70503 5.15503 2.51823C5.34183 2.33142 5.59519 2.22648 5.85938 2.22648C6.12356 2.22648 6.37692 2.33142 6.56372 2.51823C6.75052 2.70503 6.85547 2.95839 6.85547 3.22257V4.21867H5.85938C5.59519 4.21867 5.34183 4.11372 5.15503 3.92692C4.96823 3.74011 4.86328 3.48675 4.86328 3.22257ZM8.14453 14.0624H12.0117C12.1827 14.0624 12.3466 13.9945 12.4675 13.8736C12.5883 13.7528 12.6562 13.5888 12.6562 13.4179V8.43742H8.14453V14.0624ZM2.34375 13.4179C2.34375 13.5888 2.41166 13.7528 2.53253 13.8736C2.6534 13.9945 2.81734 14.0624 2.98828 14.0624H6.85547V8.43742H2.34375V13.4179Z"
              fill="white"
            />
          </svg>

          <>
            {reward?.rewardEffectValue}{" "}
            <>
              {reward?.rewardEffect === RewardRuleEffects.AddFreeItem
                ? "Free Item"
                : reward?.rewardEffect === RewardRuleEffects.AddPoints
                ? "Points"
                : ""}
            </>{" "}
            on sku <i>{reward?.sku}</i>
          </>
        </div>
      ))}

      {/* <div className="earnedPoint">
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.85547 4.21867H8.14453V7.49992H12.9492C13.1202 7.49992 13.2841 7.43201 13.405 7.31114C13.5258 7.19026 13.5938 7.02633 13.5938 6.85538V4.8632C13.5938 4.69226 13.5258 4.52832 13.405 4.40744C13.2841 4.28657 13.1202 4.21867 12.9492 4.21867H11.1967C11.434 3.72988 11.4893 3.17249 11.3526 2.64661C11.2159 2.12074 10.8961 1.66083 10.4508 1.34951C10.0055 1.0382 9.46373 0.895859 8.92289 0.948072C8.38205 1.00029 7.87755 1.24363 7.5 1.63439C7.1218 1.24577 6.61773 1.00431 6.07787 0.953163C5.53802 0.902018 4.99757 1.04452 4.55312 1.3552C4.10867 1.66588 3.78919 2.1245 3.65177 2.64907C3.51436 3.17364 3.56797 3.72999 3.80303 4.21867H2.05078C1.87984 4.21867 1.7159 4.28657 1.59503 4.40744C1.47416 4.52832 1.40625 4.69226 1.40625 4.8632V6.85538C1.40625 7.02633 1.47416 7.19026 1.59503 7.31114C1.65488 7.37099 1.72593 7.41846 1.80413 7.45085C1.88233 7.48324 1.96614 7.49992 2.05078 7.49992H6.85547V4.21867ZM8.14453 3.22257C8.14453 3.02556 8.20295 2.83298 8.3124 2.66917C8.42186 2.50537 8.57742 2.37769 8.75944 2.3023C8.94145 2.22691 9.14173 2.20718 9.33495 2.24562C9.52818 2.28405 9.70566 2.37892 9.84497 2.51823C9.98428 2.65753 10.0791 2.83502 10.1176 3.02824C10.156 3.22147 10.1363 3.42175 10.0609 3.60376C9.9855 3.78577 9.85783 3.94134 9.69403 4.05079C9.53022 4.16025 9.33763 4.21867 9.14062 4.21867H8.14453V3.22257ZM4.86328 3.22257C4.86328 2.95839 4.96823 2.70503 5.15503 2.51823C5.34183 2.33142 5.59519 2.22648 5.85938 2.22648C6.12356 2.22648 6.37692 2.33142 6.56372 2.51823C6.75052 2.70503 6.85547 2.95839 6.85547 3.22257V4.21867H5.85938C5.59519 4.21867 5.34183 4.11372 5.15503 3.92692C4.96823 3.74011 4.86328 3.48675 4.86328 3.22257ZM8.14453 14.0624H12.0117C12.1827 14.0624 12.3466 13.9945 12.4675 13.8736C12.5883 13.7528 12.6562 13.5888 12.6562 13.4179V8.43742H8.14453V14.0624ZM2.34375 13.4179C2.34375 13.5888 2.41166 13.7528 2.53253 13.8736C2.6534 13.9945 2.81734 14.0624 2.98828 14.0624H6.85547V8.43742H2.34375V13.4179Z"
            fill="white"
          />
        </svg>

        <>EarnIT - [ 50 Points ]</>
      </div> */}
    </EarnedPointBox>
  );
};

import * as React from "react";
import { WalletCheckoutBox } from "./WalletCheckout.style";
import {
  CardFrame,
  AddCardPayment,
  Loader,
  TOASTER_TYPE,
  ToastMessage,
} from "../../components";
import {
  PointCard,
  RadioCard,
  CardDetails,
  CartCard,
  OrderSummary,
  PaymentSuccess,
  Nav,
  EarnedPoint,
} from "./components";
import { Dialog } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { tokenRequest } from "../../config";
import { rewardRuleAttributes } from "../../models";
import { decodeToken, convertPriceToNaira } from "../../utils/helper";
import {
  initializeOpenSession,
  getAllRewards,
  getEarnableRewards,
  getPoints,
} from "../../services/api";
import {
  applyRewardToCartItems,
  applyRewardToTotalCart,
  getCartTotal,
} from "../../services/applyReward.service";
import { NumericFormat } from "react-number-format";
import { RewardRuleAttributeNames } from "../../types";
import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";

const WalletCheckout: React.FC<any> = () => {
  const [pointCheck, setPointCheck] = React.useState<boolean>(false);
  const [pointValue, setPointValue] = React.useState<string>("");
  const [rewardCheck, setRewardCheck] = React.useState<boolean>(false);
  const [cardCheck, setCardCheck] = React.useState<boolean>(false);
  const [openPopup, setOpenPopup] = React.useState<boolean>(false);
  const [openAddCard, setOpenAddCard] = React.useState<boolean>(false);
  const [openPaymentSuccess, setOpenPaymentSuccess] =
    React.useState<boolean>(false);
  const [tokenLoading, setTokenLoading] = React.useState<boolean>(false);
  const [accessToken, setAccessToken] = React.useState<any>(null);
  const [rewardOnCartTotal, setRewardOnCartTotal] = React.useState<any>({
    percentOff: 0,
    price: 0,
  });
  const [rewardState, setRewardState] = React.useState<any>({
    data: null,
    selectedReward: null,
    loading: false,
  });
  const [cartItems, setCartItems] = React.useState<any>(null);
  const [renderCartItems, setRenderCartItems] = React.useState<any>(null);
  const [userDetails, setUserDetails] = React.useState<any>(null);
  const [cardDetails, setCardDetails] = React.useState<any>(null);
  const [gateWayDetails, setGateWayDetails] = React.useState<any>(null);
  const [cartTotal, setCartTotal] = React.useState<number>(0);
  const { instance, accounts } = useMsal();
  const loginIframeRef = React.useRef(null);
  let popupOpened: boolean = false;

  const onInitializeOpenSession = useMutation(initializeOpenSession, {
    onSuccess: (res: any) => {
      if (res.status === 200 || res.status === 201) {
        // Clear checkout status when session is initialized
        localStorage.removeItem("c_status");

        // Retriving existing localStorage object
        const item = localStorage.getItem("session_data");
        let existingData: any = JSON.parse(item as string);
        existingData = {
          ...existingData,
          wURN: userDetails.sub,
          conURN: userDetails.extension_UserURN,
          name: userDetails.name,
        };

        // Updating local storage with wallent and consumer URN
        localStorage.setItem("session_data", JSON.stringify(existingData));

        reward_refetch(); // fetch reward
        point_refetch(); // fetch point
        en_reward_refetch(); // fetch enable rewards
      }
      if (res.response?.status === 409) {
        // Similar SessionURN but likely that cart Items has been updated
        // onUpdateOpenSession.mutate();
        return;
      }
      // ToastMessage(TOASTER_TYPE.SUCCESS, "Card token created");
    },
    onError: (error: unknown) => {
      if (axios.isAxiosError(error)) {
        console.log("card token error", error);
        // ToastMessage(TOASTER_TYPE.ERROR, error.response?.data?.message);
      }
    },
  });

  const {
    data: reward_data,
    isLoading: reward_loading,
    refetch: reward_refetch,
  } = useQuery<any>(["rewards"], getAllRewards, {
    enabled: onInitializeOpenSession.isSuccess,
    retry: 0,
    // staleTime: Infinity,
  });

  const {
    data: point_data,
    isLoading: point_loading,
    refetch: point_refetch,
  } = useQuery<any>(["points"], getPoints, {
    enabled: onInitializeOpenSession.isSuccess,
    retry: 0,
    staleTime: Infinity,
  });

  const {
    data: en_reward_data,
    isLoading: en_reward_loading,
    refetch: en_reward_refetch,
  } = useQuery<any>(["enableRewards"], getEarnableRewards, {
    enabled: onInitializeOpenSession.isSuccess,
    retry: 0,
    staleTime: Infinity,
  });

  React.useEffect(() => {
    if (accounts.length >= 1) {
      window.setTimeout(() => {
        acquireToken(accounts[0]);
        if (!cartItems) onGetSessionCart();
        setOpenPopup(false);
        popupOpened = false;
      }, 2000);
    } else {
      if (popupOpened) return;
      popupOpened = true;
      setOpenPopup(true);
    }
  }, [accounts]);

  React.useEffect(() => {
    setRewardState((prevState: any) => ({
      ...prevState,
      data: en_reward_data?.data?.data?.sessionRewardsApplicable,
    }));
  }, [en_reward_data]);

  const acquireToken = async (account: any) => {
    if (!account) {
      throw Error(
        "No active account! Verify a user has been signed in and setActiveAccount has been called."
      );
    }

    setTokenLoading(true);

    try {
      const response = await instance.acquireTokenSilent({
        ...tokenRequest,
        account: account,
      } as any);

      if (response) {
        setTokenLoading(false);
        setAccessToken(response);
        localStorage.setItem("ID_T", response.idToken);
        const decodedToken = decodeToken(response.idToken);
        setUserDetails(decodedToken);
        // request after token

        handleOpenSession(decodedToken);
      }
    } catch (err) {}
  };

  // Open Session
  const handleOpenSession = async (decoded_token: any) => {
    const payload = {
      wURN: decoded_token.sub,
      conURN: decoded_token.extension_UserURN,
      name: decoded_token.name,
    };

    onInitializeOpenSession.mutate(payload as any);
  };

  // Helper functions
  const onGetSessionCart = async () => {
    const sessionCart = JSON.parse(
      localStorage.getItem("session_data") as string
    )?.cart;

    const cartData = sessionCart?.map((item: any) => {
      return {
        ...item,
        sku: item.sku,
        price: item.price,
        quantity: item.quantity,
        newPrice: item.price,
        newQuantity: item.quantity,
        name: item.name,
        image: item.image,
        rewardType: "",
        discountPercent: null,
      };
    });

    setCartItems(cartData);
    setRenderCartItems(cartData);
  };

  // For Rewards
  const handleRewardCheck = () => {
    if (rewardCheck) {
      setRewardCheck(false);
      const rewards = rewardState.data.map((reward: any) => ({
        ...reward,
        selected: false,
      }));
      setRewardState((prevState: any) => ({
        ...prevState,
        data: rewards,
      }));
      setRenderCartItems(cartItems);
      setRewardOnCartTotal({ percentOff: 0, price: 0 });
    } else {
      setRewardCheck(true);
    }
  };

  const handleSelectReward = (selectedReward: any) => {
    setRewardCheck(true);
    const formattedData: any[] = rewardState.data.map(
      (reward: any, index: number) => {
        return {
          ...reward,
          selected:
            selectedReward.uniqueReferenceNumber ===
            reward.uniqueReferenceNumber
              ? true
              : false,
        };
      }
    );

    setRewardState((prevState: any) => ({
      ...prevState,
      data: formattedData,
    }));

    // Rewards that apply to cart items only
    const newCart = applyRewardToCartItems(selectedReward, cartItems);
    setRenderCartItems(newCart);

    let rewardDiscountedValue = newCart.filter(
      (cart: any) => cart.rewardType !== ""
    );

    // Rewards that apply to Cart total only
    const cartTotalReward = applyRewardToTotalCart(
      selectedReward,
      getCartTotal(cartItems)
    )
      ? applyRewardToTotalCart(selectedReward, getCartTotal(cartItems))
      : {
          percentOff: 0,
          price: 0,
        };

    const rewardAnddiscountInAmount =
      rewardDiscountedValue.length === 0
        ? { ...selectedReward, discountAmountApplied: cartTotalReward?.price }
        : {
            ...selectedReward,
            discountAmountApplied:
              (rewardDiscountedValue[0].price -
                rewardDiscountedValue[0].newPrice) *
              rewardDiscountedValue[0].quantity,
          };

    setRewardState((prevState: any) => ({
      ...prevState,
      data: formattedData,
      selectedReward: rewardAnddiscountInAmount,
    }));

    setRewardOnCartTotal((prevState: any) => ({
      ...prevState,
      percentOff: cartTotalReward?.percentOff,
      price: cartTotalReward?.price,
    }));
  };

  // For Point
  const handlePointValue = (point: string) => {
    setPointValue(point);
  };

  // For Card Details
  const handleGetCardFromComponent = (data: any) => {
    setCardDetails(data.card);
    setGateWayDetails(data.gateway);
  };

  // For Order Summary
  // Updating Cart Total globally
  const handleSettingCartTotal = (updatedPrice: number) => {
    setCartTotal(updatedPrice);
  };

  const handleClose = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") return;
    setOpenPopup(false);
  };

  const userPointDetail =
    point_data && point_data?.data?.data[point_data?.data?.data.length - 1];
  const cartEarnableReward = en_reward_data && en_reward_data?.data;

  return (
    <>
      {(tokenLoading ||
        reward_loading ||
        point_loading ||
        en_reward_loading ||
        onInitializeOpenSession.isLoading) && <Loader isFullPage={true} />}

      {!tokenLoading && accessToken && (
        <WalletCheckoutBox>
          <div className="container">
            <Nav name={userDetails?.name} />
            <div className="header mb-4">
              <h3 className="title mb-1 mt-4">
                Apply your rewards to save and earn more
              </h3>
              <p className="details">
                Save big on your next purchase, receive discounts. redeem
                points, free gifts and many more.
              </p>
            </div>

            <div className="row">
              <div className="col-lg-7">
                {/* Point section */}
                <div className="mb-3">
                  <CardFrame
                    hasIcon={true}
                    icon={
                      <svg
                        className="icon"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.3125 11L16.1563 2.0625H10.3014L8.4176 5.32812H14.2704L17.5424 11L14.2704 16.6719H8.4176L10.3014 19.9375H16.1563L21.3125 11Z"
                          fill="#00AE97"
                        />
                        <path
                          d="M7.73008 16.6719L4.45758 11L7.73008 5.32812L9.61383 2.0625H5.84375L0.6875 11L5.84375 19.9375H9.61383L7.73008 16.6719Z"
                          fill="#FF6854"
                        />
                      </svg>
                    }
                    title={"Points"}
                    subtitle={
                      userPointDetail
                        ? `Jumia Points ${pointValue}`
                        : "No loyalty membership enrolled to your account."
                    }
                    handleCheck={() => {
                      setPointCheck(!pointCheck);
                    }}
                    checkState={pointCheck}
                  >
                    {userPointDetail && (
                      <>
                        <PointCard
                          isPointChecked={pointCheck}
                          handleChange={handlePointValue}
                          totalAmount={
                            renderCartItems ? getCartTotal(renderCartItems) : 0
                          }
                          updatedCartTotal={cartTotal}
                          userPointDetails={userPointDetail}
                        />
                        <small className="info">
                          Your order total will be adjusted and any reward due
                          shown
                        </small>
                      </>
                    )}
                  </CardFrame>
                </div>

                {/* Reward section */}
                <div className="mb-3">
                  <CardFrame
                    hasIcon={true}
                    icon={
                      <svg
                        className="icon"
                        width="22"
                        height="23"
                        viewBox="0 0 22 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 4.56397V6.97022H13.4063C13.8822 6.97022 14.3474 6.82909 14.7431 6.56469C15.1388 6.30029 15.4472 5.92448 15.6293 5.4848C15.8115 5.04511 15.8591 4.5613 15.7663 4.09453C15.6734 3.62776 15.4442 3.19901 15.1077 2.86249C14.7712 2.52597 14.3425 2.2968 13.8757 2.20395C13.4089 2.11111 12.9251 2.15876 12.4854 2.34088C12.0457 2.523 11.6699 2.83142 11.4055 3.22713C11.1411 3.62283 11 4.08805 11 4.56397V4.56397ZM11 4.56397V6.97022H8.59375C8.11784 6.97022 7.65262 6.82909 7.25691 6.56469C6.8612 6.30029 6.55279 5.92448 6.37067 5.4848C6.18854 5.04511 6.14089 4.5613 6.23374 4.09453C6.32658 3.62776 6.55576 3.19901 6.89228 2.86249C7.2288 2.52597 7.65755 2.2968 8.12431 2.20395C8.59108 2.11111 9.0749 2.15876 9.51458 2.34088C9.95427 2.523 10.3301 2.83142 10.5945 3.22713C10.8589 3.62283 11 4.08805 11 4.56397V4.56397Z"
                          stroke="#FF5349"
                          stroke-width="2"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                        />
                        <path
                          d="M17.875 6.97021H4.125C3.36561 6.97021 2.75 7.58582 2.75 8.34521V10.4077C2.75 11.1671 3.36561 11.7827 4.125 11.7827H17.875C18.6344 11.7827 19.25 11.1671 19.25 10.4077V8.34521C19.25 7.58582 18.6344 6.97021 17.875 6.97021Z"
                          stroke="#FF5349"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.875 11.7827V17.9702C17.875 18.5172 17.6577 19.0418 17.2709 19.4286C16.8841 19.8154 16.3595 20.0327 15.8125 20.0327H6.1875C5.64049 20.0327 5.11589 19.8154 4.72909 19.4286C4.3423 19.0418 4.125 18.5172 4.125 17.9702V11.7827M11 6.97021V20.0327"
                          stroke="#FF5349"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    }
                    title={"Rewards"}
                    subtitle={
                      rewardState.data
                        ? "Please select one"
                        : "No rewards earned on your account yet."
                    }
                    handleCheck={handleRewardCheck}
                    checkState={rewardCheck}
                  >
                    {rewardState.data && (
                      <>
                        <div className="d-flex gap-3 flex-column">
                          {rewardState.data.map(
                            (reward: any, index: number) => {
                              return (
                                <RadioCard
                                  key={`${index}-${reward.createdAt}`}
                                  title={reward?.rewardName}
                                  details={reward?.rewardReason}
                                  endDate={""}
                                  isSelected={reward.selected}
                                  click={() => handleSelectReward(reward)}
                                />
                              );
                            }
                          )}
                        </div>

                        <small className="info">
                          Your order total will be adjusted and any reward due
                          shown
                        </small>
                      </>
                    )}
                  </CardFrame>
                </div>

                {/* Card Details */}
                {userDetails && (
                  <div className="mb-3">
                    <CardFrame
                      hasIcon={true}
                      icon={
                        <svg
                          className="icon"
                          width="22"
                          height="23"
                          viewBox="0 0 22 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.5312 4.22021H4.46875C3.13981 4.22021 2.0625 5.29753 2.0625 6.62646V15.564C2.0625 16.8929 3.13981 17.9702 4.46875 17.9702H17.5312C18.8602 17.9702 19.9375 16.8929 19.9375 15.564V6.62646C19.9375 5.29753 18.8602 4.22021 17.5312 4.22021Z"
                            stroke="#00AE97"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M2.0625 8.34521H19.9375M5.5 12.9858H7.5625V13.8452H5.5V12.9858Z"
                            stroke="#00AE97"
                            stroke-width="2"
                            stroke-linejoin="round"
                          />
                        </svg>
                      }
                      title={"Card Details"}
                      subtitle={
                        cardDetails
                          ? "Select card to make payment"
                          : "No card available please add a payment method."
                      }
                      handleCheck={() => {
                        setCardCheck(!cardCheck);
                      }}
                      checkState={cardCheck}
                    >
                      <CardDetails
                        userDetails={userDetails}
                        getCardDetails={handleGetCardFromComponent}
                      />
                    </CardFrame>
                  </div>
                )}
              </div>

              <div className="col-lg-5">
                {/* Cart section */}
                <div className="mb-3">
                  <CardFrame
                    title={"Items in cart"}
                    subtitle={`${
                      renderCartItems ? renderCartItems.length : 0
                    } items in cart`}
                    hasCheckbox={false}
                  >
                    <div className="d-flex flex-column gap-3">
                      {renderCartItems &&
                        renderCartItems.map(
                          ({
                            sku,
                            price,
                            quantity,
                            name,
                            image,
                            newPrice,
                            newQuantity,
                            rewardType,
                            discountPercent,
                          }: any) => (
                            <CartCard
                              key={sku}
                              sku={sku}
                              price={price}
                              name={name}
                              image={image}
                              quantity={quantity}
                              newPrice={newPrice}
                              newQuantity={newQuantity}
                              rewardType={rewardType}
                              discount={discountPercent}
                              gateWayDetails={gateWayDetails}
                            />
                          )
                        )}
                    </div>
                  </CardFrame>
                </div>

                {/* Order Summary */}
                <div className="mb-3">
                  <CardFrame
                    title={"Order Summary"}
                    subtitle={
                      renderCartItems ? (
                        <NumericFormat
                          value={convertPriceToNaira(
                            getCartTotal(renderCartItems)
                          )}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={gateWayDetails?.currencySymbol}
                        />
                      ) : (
                        `${gateWayDetails?.currencySymbol}0`
                      )
                    }
                    isOrderSummary={true}
                    hasCheckbox={false}
                  >
                    <>
                      <OrderSummary
                        totalCost={
                          renderCartItems ? getCartTotal(renderCartItems) : 0
                        }
                        rawCartTotal={getCartTotal(renderCartItems)}
                        updatedCartTotal={handleSettingCartTotal}
                        isCardSelected={cardCheck}
                        pointData={{
                          check: pointCheck,
                          value: pointValue,
                          userPointDetail,
                        }}
                        rewardData={{
                          isRewardSelected: rewardCheck,
                          selectedReward: rewardState.selectedReward,
                          onCartTotal: rewardOnCartTotal,
                        }}
                        cardDetails={cardDetails}
                        gateWayDetails={gateWayDetails}
                      />
                    </>
                  </CardFrame>
                </div>

                {/* Earned Point */}
                {cartEarnableReward && (
                  <div>
                    <CardFrame title={"Rewards Earned"} hasCheckbox={false}>
                      <EarnedPoint
                        cartItemEarnable={
                          en_reward_data?.data?.data?.cartItemRewardsEarnable
                        }
                        cartTotalEarnable={
                          en_reward_data?.data?.data?.cartTotalRewardsEarnable
                        }
                      />
                    </CardFrame>
                  </div>
                )}
              </div>
            </div>
          </div>
        </WalletCheckoutBox>
      )}

      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={openPaymentSuccess}
        onClose={() => {
          setOpenPaymentSuccess(!openPaymentSuccess);
        }}
        sx={{
          "& 	.MuiDialog-paperWidthMd": {
            // height: "500px",
            width: "550px",
            "@media (max-width: 767px)": {
              width: "92%",
              margin: "auto",
            },
          },
        }}
      >
        <PaymentSuccess />
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={openAddCard}
        onClose={() => {
          setOpenAddCard(!openAddCard);
        }}
        sx={{
          "& 	.MuiDialog-paperWidthMd": {
            // height: "500px",
            width: "550px",
            "@media (max-width: 767px)": {
              width: "92%",
              margin: "auto",
            },
          },
        }}
      >
        <AddCardPayment />
      </Dialog>

      {openPopup && (
        <div>
          <Dialog
            disableEscapeKeyDown
            fullWidth={true}
            maxWidth={"md"}
            open={openPopup}
            onClose={handleClose}
            sx={{
              "& 	.MuiDialog-paperWidthMd": {
                height: "600px",
                width: "500px",
                position: "relative",
                "& .MuiButtonBase-root": {
                  position: "absolute",
                  top: "1.5rem",
                  right: "1.5rem",
                  zIndex: 5,
                },
                "@media (max-width: 767px)": {
                  width: "92%",
                  margin: "auto",
                },
              },
            }}
          >
            <iframe
              ref={loginIframeRef}
              id="loginIframe"
              width="100%"
              height="100%"
              src={"/b2cLogin"}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Login Iframe"
            />
          </Dialog>
        </div>
      )}
    </>
  );
};

export default WalletCheckout;

import { styled } from "@mui/material";

export const OrderBox = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "150px",

  justifyContent: "space-between",

  "& ul": {
    paddingLeft: "0",

    "& li": {
      display: "flex",
      justifyContent: "space-between",
      padding: "0.5rem 0",
      fontSize: "1rem",

      "& .key": {
        color: "#847F7F",
        fontWeight: 400,
      },

      "& .rewardAppliedBadge": {
        padding: "0.2rem 0.4rem",
        border: "1px solid #00AE97",
        borderRadius: "4px",
        display: "inline-flex",
        alignItems: "center",
        gap: "0 0.2rem",
        fontSize: "0.625rem",
        fontWeight: 400,
        color: "#00AE97",
        width: "fit-content"
      },

      "& .value": {
        color: "#847F7F",
        fontWeight: 600,
      },

      "& .subTotal": {
        color: "#150200",
      },

      "& .total": {
        fontWeight: 600,
        color: "#150200",
      },
    },
  },

  //   Styles for screen of width 767.9px and below
  "@media (max-width:991.9px)": {
    "& ul": {
      "& li": {
        fontSize: "0.875rem",
        // gap: "0.5rem",
        display: "grid",
        gridTemplateColumns: "65% 35%",

        "& .key": {
          color: "#847F7F",
          fontWeight: 400,
          marginRight: "0.5rem"
        },

        "& .value": {
          color: "#847F7F",
          fontWeight: 600,
          textAlign: "end",
        },

        "& .subTotal": {
          color: "#150200",
        },

        "& .total": {
          fontWeight: 600,
          color: "#150200",
        },
      },
    },
  },
}));

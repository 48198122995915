import {
  RewardRuleAttributeNames,
  RewardRuleAttributeCriteria,
  RewardRuleEffects,
  RewardRuleAttributeValueDescriptions,
} from "../types";
import { ICartItem, ICartTotalReward } from "../types/interfaces";

export const applyRewardToCartItems = (
  selectedReward: any,
  cartItems: any[]
): any => {
  let rewardResult: any[] = cartItems;

  selectedReward.rewardRules.forEach((reward_rules: any) => {
    // cartItem rules
    if (reward_rules.attributeName === RewardRuleAttributeNames.CartItem) {
      rewardResult = applyToCartItem(
        selectedReward,
        cartItems,
        RewardRuleAttributeNames.CartItem
      );
    }

    // cartItemQuantity rules
    if (
      reward_rules.attributeName === RewardRuleAttributeNames.CartItemQuantity
    ) {
      rewardResult = applyToCartItemQuantity(
        selectedReward,
        cartItems,
        RewardRuleAttributeNames.CartItemQuantity
      );
    }

    // cartItemValue rules
    if (reward_rules.attributeName === RewardRuleAttributeNames.CartItemValue) {
      rewardResult = applyToCartItemValue(
        selectedReward,
        cartItems,
        RewardRuleAttributeNames.CartItemValue
      );
    }
  });

  return rewardResult;

  // const rewardForCartItem = {
  //   ...selectedReward,
  //   rewardRule: getRewardRule(
  //     selectedReward.rewardRules,
  //     RewardRuleAttributeNames.CartItem
  //   ),
  // };

  // let cartWithRewardApplied: any[] = [];

  // // Function stop here is none of the rules applies to CartItem
  // if (rewardForCartItem.rewardRule.length === 0) return cartItems;

  // // Check for rewardEffect on cartItems
  // cartItems.forEach((item: any) => {
  //   rewardForCartItem.rewardRule.forEach((rule_item: any) => {
  //     console.log(item.sku, rule_item.value);
  //     // if Sku matches the atrributeValue
  //     if (item.sku === rule_item.value) {
  //       // If reward is AddDiscount
  //       if (rewardForCartItem.rewardEffect === RewardRuleEffects.AddDiscount) {
  //         // console.log("Adding discount");
  //         item = {
  //           ...item,
  //           rewardType: RewardRuleEffects.AddDiscount,
  //           newPrice:
  //             Number(item.price) -
  //             calculateDiscountOnPrice(
  //               parseInt(item.price),
  //               Number(rewardForCartItem.rewardEffectValue)
  //             ),
  //           discountPercent: Number(rewardForCartItem.rewardEffectValue) * 100,
  //         };
  //       }

  //       // If reward is FreeItem
  //       else if (
  //         rewardForCartItem.rewardEffect === RewardRuleEffects.AddFreeItem
  //       ) {
  //         // console.log("Add Free Item");
  //         item = {
  //           ...item,
  //           rewardType: RewardRuleEffects.AddFreeItem,
  //           newPrice: calculateNewPriceFroFreeItem(
  //             Number(item.quantity),
  //             Number(item.price)
  //           ),
  //         };
  //       }

  //       // If reward is AddPoint
  //       else if (
  //         rewardForCartItem.rewardEffect === RewardRuleEffects.AddPoints
  //       ) {
  //         // console.log("Add Free Item");
  //         item = {
  //           ...item,
  //           rewardType: RewardRuleEffects.AddPoints,
  //           newPrice: calculateNewPriceFroFreeItem(
  //             Number(item.quantity),
  //             Number(item.price)
  //           ),
  //         };
  //       }
  //     }
  //   });

  //   cartWithRewardApplied.push(item);
  // });

  // return cartWithRewardApplied;
};

export const applyRewardToTotalCart = (
  selectedReward: any,
  cartTotal: any
): any => {
  const rewardForTotalCart = {
    ...selectedReward,
    rewardRule: getRewardRule(
      selectedReward.rewardRules,
      RewardRuleAttributeNames.CartTotalValue
    ),
  };

  let cartTotalReward: ICartTotalReward = {
    percentOff: 0,
    price: 0,
  };

  rewardForTotalCart.rewardRule.forEach((rule_item: any) => {
    // Check for criteria
    if (
      rule_item.valueCriteria ===
      RewardRuleAttributeCriteria.GreaterThanOrEqualTo
    ) {
      // Cart total must be >= to attribute value for this section
      if (rewardForTotalCart.rewardEffect === RewardRuleEffects.AddDiscount) {
        cartTotalReward = evaluatedCartTotalReward(
          rewardForTotalCart,
          cartTotal
        );

        // console.log(cartTotalReward);
        return cartTotalReward;
      }
    } else if (
      rule_item.valueCriteria === RewardRuleAttributeCriteria.EqualTo
    ) {
      // Cart total must be == to attribute value for this section
      if (rewardForTotalCart.rewardEffect === RewardRuleEffects.AddDiscount) {
        cartTotalReward = evaluatedCartTotalReward(
          rewardForTotalCart,
          cartTotal
        );

        // console.log(cartTotalReward);
        return cartTotalReward;
      }
    } else if (
      rule_item.valueCriteria === RewardRuleAttributeCriteria.LessThanOrEqualTo
    ) {
      // Cart total must be <= to attribute value for this section
      if (rewardForTotalCart.rewardEffect === RewardRuleEffects.AddDiscount) {
        cartTotalReward = evaluatedCartTotalReward(
          rewardForTotalCart,
          cartTotal
        );

        // console.log(cartTotalReward);
        return cartTotalReward;
      }
    }
  });

  return cartTotalReward;
};

export const applyToCartItem = (
  selectedReward: any,
  cartItems: any[],
  rewardAttributeName: string
) => {
  const rewardForCartItem = {
    ...selectedReward,
    rewardRule: getRewardRule(selectedReward.rewardRules, rewardAttributeName),
  };

  let cartWithRewardApplied: any[] = [];

  // Function stop here is none of the rules applies to CartItem
  if (rewardForCartItem.rewardRule.length === 0) return cartItems;

  // Check for rewardEffect on cartItems
  cartItems.forEach((item: any) => {
    /********* RULE 1 *****/

    // Check if cart item sku is equal to reward rule sku value
    if (item.sku === rewardForCartItem.rewardRule[0].value) {
      /********* RULE 2 *****/
      // Monetary
      if (
        rewardForCartItem.rewardRule[1].valueDescription ===
        RewardRuleAttributeValueDescriptions.Monetary
      ) {
        // Monetary - GreaterThan
        if (
          rewardForCartItem.rewardRule[1].valueCriteria ===
          RewardRuleAttributeCriteria.GreaterThan
        ) {
          if (
            Number(item.quantity) * Number(item.price) >
            Number(rewardForCartItem.rewardRule[1].value)
          ) {
            // If reward is AddDiscount
            if (
              rewardForCartItem.rewardEffect === RewardRuleEffects.AddDiscount
            ) {
              item = evaluateAddDiscount(item, rewardForCartItem);
            }

            // If reward is FreeItem
            if (
              rewardForCartItem.rewardEffect === RewardRuleEffects.AddFreeItem
            ) {
              item = evaluateAddFreeItem(item);
            }
          }
        }

        // Monetary - GreaterThanOrEqualTo
        if (
          rewardForCartItem.rewardRule[1].valueCriteria ===
          RewardRuleAttributeCriteria.GreaterThanOrEqualTo
        ) {
          if (
            Number(item.quantity) * Number(item.price) >=
            Number(rewardForCartItem.rewardRule[1].value)
          ) {
            // If reward is AddDiscount
            if (
              rewardForCartItem.rewardEffect === RewardRuleEffects.AddDiscount
            ) {
              item = evaluateAddDiscount(item, rewardForCartItem);
            }

            // If reward is FreeItem
            if (
              rewardForCartItem.rewardEffect === RewardRuleEffects.AddFreeItem
            ) {
              item = evaluateAddFreeItem(item);
            }
          }
        }

        // Monetary - EqualTo
        if (
          rewardForCartItem.rewardRule[1].valueCriteria ===
          RewardRuleAttributeCriteria.EqualTo
        ) {
          if (
            Number(item.quantity) * Number(item.price) ===
            Number(rewardForCartItem.rewardRule[1].value)
          ) {
            // If reward is AddDiscount
            if (
              rewardForCartItem.rewardEffect === RewardRuleEffects.AddDiscount
            ) {
              item = evaluateAddDiscount(item, rewardForCartItem);
            }

            // If reward is FreeItem
            if (
              rewardForCartItem.rewardEffect === RewardRuleEffects.AddFreeItem
            ) {
              item = evaluateAddFreeItem(item);
            }
          }
        }
      }

      // Quantity
      if (
        rewardForCartItem.rewardRule[1].valueDescription ===
        RewardRuleAttributeValueDescriptions.Quantity
      ) {
        // Quantity - GreaterThan
        if (
          rewardForCartItem.rewardRule[1].valueCriteria ===
          RewardRuleAttributeCriteria.GreaterThan
        ) {
          if (item.quantity > rewardForCartItem.rewardRule[1].value) {
            console.log("Passed greater than and quantity check");
            // If reward is AddDiscount
            if (
              rewardForCartItem.rewardEffect === RewardRuleEffects.AddDiscount
            ) {
              item = evaluateAddDiscount(item, rewardForCartItem);
            }

            // If reward is FreeItem
            if (
              rewardForCartItem.rewardEffect === RewardRuleEffects.AddFreeItem
            ) {
              item = evaluateAddFreeItem(item);
            }
          }
        }

        // Quantity - GreaterThanOrEqualTo
        if (
          rewardForCartItem.rewardRule[1].valueCriteria ===
          RewardRuleAttributeCriteria.GreaterThanOrEqualTo
        ) {
          // If reward is AddDiscount
          if (item.quantity >= rewardForCartItem.rewardRule[1].value) {
            if (
              rewardForCartItem.rewardEffect === RewardRuleEffects.AddDiscount
            ) {
              item = evaluateAddDiscount(item, rewardForCartItem);
            }

            // If reward is FreeItem
            if (
              rewardForCartItem.rewardEffect === RewardRuleEffects.AddFreeItem
            ) {
              item = evaluateAddFreeItem(item);
            }
          }
        }

        // Quantity - EqualTo
        if (
          rewardForCartItem.rewardRule[1].valueCriteria ===
          RewardRuleAttributeCriteria.EqualTo
        ) {
          if (item.quantity === rewardForCartItem.rewardRule[1].value) {
            // If reward is AddDiscount
            if (
              rewardForCartItem.rewardEffect === RewardRuleEffects.AddDiscount
            ) {
              item = evaluateAddDiscount(item, rewardForCartItem);
            }

            // If reward is FreeItem
            if (
              rewardForCartItem.rewardEffect === RewardRuleEffects.AddFreeItem
            ) {
              item = evaluateAddFreeItem(item);
            }
          }
        }
      }
    }

    cartWithRewardApplied.push(item);
  });

  return cartWithRewardApplied;
};

export const applyToCartItemQuantity = (
  selectedReward: any,
  cartItems: any[],
  rewardAttributeName: string
) => {
  const rewardForCartItem = {
    ...selectedReward,
    rewardRule: getRewardRule(selectedReward.rewardRules, rewardAttributeName),
  };

  let cartWithRewardApplied: any[] = [];

  // Function stop here is none of the rules applies to CartItem
  if (rewardForCartItem.rewardRule.length === 0) return cartItems;

  // Check for rewardEffect on cartItems
  cartItems.forEach((item: any) => {
    /********* RULE 1 *****/

    // Check if cart item sku is equal to reward rule sku value
    if (item.sku === rewardForCartItem.rewardRule[0].value) {
      /********* RULE 2 *****/
      // Quantity
      if (
        rewardForCartItem.rewardRule[1].valueDescription ===
        RewardRuleAttributeValueDescriptions.Quantity
      ) {
        // Monetary - GreaterThan
        if (
          rewardForCartItem.rewardRule[1].valueCriteria ===
          RewardRuleAttributeCriteria.GreaterThan
        ) {
          // If reward is AddDiscount
          if (
            rewardForCartItem.rewardEffect === RewardRuleEffects.AddDiscount
          ) {
            item = evaluateAddDiscount(item, rewardForCartItem);
          }

          // If reward is FreeItem
          if (
            rewardForCartItem.rewardEffect === RewardRuleEffects.AddFreeItem
          ) {
            item = evaluateAddFreeItem(item);
          }
        }

        // Monetary - GreaterThanOrEqualTo
        if (
          rewardForCartItem.rewardRule[1].valueCriteria ===
          RewardRuleAttributeCriteria.GreaterThanOrEqualTo
        ) {
          // If reward is AddDiscount
          if (
            rewardForCartItem.rewardEffect === RewardRuleEffects.AddDiscount
          ) {
            item = evaluateAddDiscount(item, rewardForCartItem);
          }

          // If reward is FreeItem
          if (
            rewardForCartItem.rewardEffect === RewardRuleEffects.AddFreeItem
          ) {
            item = evaluateAddFreeItem(item);
          }
        }

        // Monetary - EqualTo
        if (
          rewardForCartItem.rewardRule[1].valueCriteria ===
          RewardRuleAttributeCriteria.EqualTo
        ) {
          // If reward is AddDiscount
          if (
            rewardForCartItem.rewardEffect === RewardRuleEffects.AddDiscount
          ) {
            item = evaluateAddDiscount(item, rewardForCartItem);
          }

          // If reward is FreeItem
          if (
            rewardForCartItem.rewardEffect === RewardRuleEffects.AddFreeItem
          ) {
            item = evaluateAddFreeItem(item);
          }
        }
      }

      // Monetary
      if (
        rewardForCartItem.rewardRule[1].valueDescription ===
        RewardRuleAttributeValueDescriptions.Monetary
      ) {
        // Monetary - GreaterThan
        if (
          rewardForCartItem.rewardRule[1].valueCriteria ===
          RewardRuleAttributeCriteria.GreaterThan
        ) {
          // If reward is AddDiscount
          if (
            rewardForCartItem.rewardEffect === RewardRuleEffects.AddDiscount
          ) {
            item = evaluateAddDiscount(item, rewardForCartItem);
          }

          // If reward is FreeItem
          if (
            rewardForCartItem.rewardEffect === RewardRuleEffects.AddFreeItem
          ) {
            item = evaluateAddFreeItem(item);
          }
        }

        // Monetary - GreaterThanOrEqualTo
        if (
          rewardForCartItem.rewardRule[1].valueCriteria ===
          RewardRuleAttributeCriteria.GreaterThanOrEqualTo
        ) {
          // If reward is AddDiscount
          if (
            rewardForCartItem.rewardEffect === RewardRuleEffects.AddDiscount
          ) {
            item = evaluateAddDiscount(item, rewardForCartItem);
          }

          // If reward is FreeItem
          if (
            rewardForCartItem.rewardEffect === RewardRuleEffects.AddFreeItem
          ) {
            item = evaluateAddFreeItem(item);
          }
        }

        // Monetary - EqualTo
        if (
          rewardForCartItem.rewardRule[1].valueCriteria ===
          RewardRuleAttributeCriteria.EqualTo
        ) {
          // If reward is AddDiscount
          if (
            rewardForCartItem.rewardEffect === RewardRuleEffects.AddDiscount
          ) {
            item = evaluateAddDiscount(item, rewardForCartItem);
          }

          // If reward is FreeItem
          if (
            rewardForCartItem.rewardEffect === RewardRuleEffects.AddFreeItem
          ) {
            item = evaluateAddFreeItem(item);
          }
        }
      }
    }

    cartWithRewardApplied.push(item);
  });

  return cartWithRewardApplied;
};

export const applyToCartItemValue = (
  selectedReward: any,
  cartItems: any[],
  rewardAttributeName: string
) => {
  const rewardForCartItem = {
    ...selectedReward,
    rewardRule: getRewardRule(selectedReward.rewardRules, rewardAttributeName),
  };

  let cartWithRewardApplied: any[] = [];

  // Function stop here is none of the rules applies to CartItem
  if (rewardForCartItem.rewardRule.length === 0) return cartItems;

  // Check for rewardEffect on cartItems
  cartItems.forEach((item: any) => {
    /********* RULE 1 *****/

    // Check if cart item sku is equal to reward rule sku value
    if (item.sku === rewardForCartItem.rewardRule[0].value) {
      /********* RULE 2 *****/
      // Monetary
      if (
        rewardForCartItem.rewardRule[1].valueDescription ===
        RewardRuleAttributeValueDescriptions.Monetary
      ) {
        // Monetary - GreaterThan
        if (
          rewardForCartItem.rewardRule[1].valueCriteria ===
          RewardRuleAttributeCriteria.GreaterThan
        ) {
          if (
            Number(item.quantity) * Number(item.price) >
            Number(rewardForCartItem.rewardRule[1].value)
          ) {
            // If reward is AddDiscount
            if (
              rewardForCartItem.rewardEffect === RewardRuleEffects.AddDiscount
            ) {
              item = evaluateAddDiscount(item, rewardForCartItem);
            }

            // If reward is FreeItem
            if (
              rewardForCartItem.rewardEffect === RewardRuleEffects.AddFreeItem
            ) {
              item = evaluateAddFreeItem(item);
            }
          }
        }

        // Monetary - GreaterThanOrEqualTo
        if (
          rewardForCartItem.rewardRule[1].valueCriteria ===
          RewardRuleAttributeCriteria.GreaterThanOrEqualTo
        ) {
          if (
            Number(item.quantity) * Number(item.price) >=
            Number(rewardForCartItem.rewardRule[1].value)
          ) {
            // If reward is AddDiscount
            if (
              rewardForCartItem.rewardEffect === RewardRuleEffects.AddDiscount
            ) {
              item = evaluateAddDiscount(item, rewardForCartItem);
            }

            // If reward is FreeItem
            if (
              rewardForCartItem.rewardEffect === RewardRuleEffects.AddFreeItem
            ) {
              item = evaluateAddFreeItem(item);
            }
          }
        }

        // Monetary - EqualTo
        if (
          rewardForCartItem.rewardRule[1].valueCriteria ===
          RewardRuleAttributeCriteria.EqualTo
        ) {
          if (
            Number(item.quantity) * Number(item.price) ===
            Number(rewardForCartItem.rewardRule[1].value)
          ) {
            // If reward is AddDiscount
            if (
              rewardForCartItem.rewardEffect === RewardRuleEffects.AddDiscount
            ) {
              item = evaluateAddDiscount(item, rewardForCartItem);
            }

            // If reward is FreeItem
            if (
              rewardForCartItem.rewardEffect === RewardRuleEffects.AddFreeItem
            ) {
              item = evaluateAddFreeItem(item);
            }
          }
        }
      }

      // Quantity
      if (
        rewardForCartItem.rewardRule[1].valueDescription ===
        RewardRuleAttributeValueDescriptions.Quantity
      ) {
        // Quantity - GreaterThan
        if (
          rewardForCartItem.rewardRule[1].valueCriteria ===
          RewardRuleAttributeCriteria.GreaterThan
        ) {
          if (item.quantity > rewardForCartItem.rewardRule[1].value) {
            // If reward is AddDiscount
            if (
              rewardForCartItem.rewardEffect === RewardRuleEffects.AddDiscount
            ) {
              item = evaluateAddDiscount(item, rewardForCartItem);
            }

            // If reward is FreeItem
            if (
              rewardForCartItem.rewardEffect === RewardRuleEffects.AddFreeItem
            ) {
              item = evaluateAddFreeItem(item);
            }
          }
        }

        // Quantity - GreaterThanOrEqualTo
        if (
          rewardForCartItem.rewardRule[1].valueCriteria ===
          RewardRuleAttributeCriteria.GreaterThanOrEqualTo
        ) {
          // If reward is AddDiscount
          if (item.quantity >= rewardForCartItem.rewardRule[1].value) {
            if (
              rewardForCartItem.rewardEffect === RewardRuleEffects.AddDiscount
            ) {
              item = evaluateAddDiscount(item, rewardForCartItem);
            }

            // If reward is FreeItem
            if (
              rewardForCartItem.rewardEffect === RewardRuleEffects.AddFreeItem
            ) {
              item = evaluateAddFreeItem(item);
            }
          }
        }

        // Quantity - EqualTo
        if (
          rewardForCartItem.rewardRule[1].valueCriteria ===
          RewardRuleAttributeCriteria.EqualTo
        ) {
          if (item.quantity === rewardForCartItem.rewardRule[1].value) {
            // If reward is AddDiscount
            if (
              rewardForCartItem.rewardEffect === RewardRuleEffects.AddDiscount
            ) {
              item = evaluateAddDiscount(item, rewardForCartItem);
            }

            // If reward is FreeItem
            if (
              rewardForCartItem.rewardEffect === RewardRuleEffects.AddFreeItem
            ) {
              item = evaluateAddFreeItem(item);
            }
          }
        }
      }
    }

    cartWithRewardApplied.push(item);
  });

  return cartWithRewardApplied;
};

// Reward utilies
export const evaluateAddDiscount = (item: any, rewardForCartItem: any) => {
  const effectValue = Number(
    Number(rewardForCartItem.rewardEffectValue).toFixed(3)
  );

  return {
    ...item,
    rewardType: RewardRuleEffects.AddDiscount,
    newPrice:
      Number(item.price) -
      calculateDiscountOnPrice(parseInt(item.price), effectValue),
    discountPercent: effectValue * 100,
  };
};

export const evaluateAddFreeItem = (item: any) => {
  return (item = {
    ...item,
    rewardType: RewardRuleEffects.AddFreeItem,
    newPrice: calculateNewPriceFroFreeItem(
      Number(item.quantity),
      Number(item.price)
    ),
  });
};

// General Utilies
export const getRewardRule = (
  rewardRules: any,
  rewardAttributeName: string
) => {
  let rewardRule: any[] = [];

  rewardRules.forEach((rule: any) => {
    if (rule.attributeName === rewardAttributeName) {
      rewardRule = [...rule.rewardRuleEvaluationDescriptions];
    }
  });

  console.log(rewardRule, "rule");

  return rewardRule;
};

export const calculateDiscountOnPrice = (price: number, discount: number) => {
  const discountedPrice = price * discount;

  if (discountedPrice === Math.floor(discountedPrice)) {
    // If the discounted price is an integer, return it as a whole number
    return Number(discountedPrice.toFixed(0));
  } else {
    // If the discounted price has a fractional part, return it with 2 decimal places
    return Number(discountedPrice.toFixed(3));
  }
};

export const calculateNewPriceFroFreeItem = (
  itemQty: number,
  price: number
) => {
  // price on each qauntity
  const priceOnAnItem = price / itemQty;

  // Determine the number of free items the user will receive
  const numFreeItems = Math.floor(itemQty / 2); // Round down to the nearest integer

  // Determine the number of paid items the user will purchase
  const numPaidItems = itemQty - numFreeItems;

  // total cost after considering free item
  const totalCost = numPaidItems * priceOnAnItem;

  // console.log(totalCost);

  return totalCost;
};

export const getCartTotal = (cart: any[]) => {
  let totalPrice: number = 0;

  if (!cart) return 0;

  cart.forEach((item) => {
    const actualPrice = Number(item.newPrice) * Number(item.quantity);

    totalPrice = totalPrice + actualPrice;
  });

  return totalPrice;
};

export const evaluatedCartTotalReward = (
  reward_for_cart_total: any,
  cart_total_amount: number
): ICartTotalReward => {
  const cartTotalReward = {
    percentOff: reward_for_cart_total.rewardEffectValue * 100, // convert to percentage
    price: calculateDiscountOnPrice(
      cart_total_amount,
      reward_for_cart_total.rewardEffectValue
    ),
  };

  return cartTotalReward;
};

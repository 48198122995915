import { styled } from "@mui/material";

export const CardDetailsBox = styled("div")(({ theme }) => ({
  "& ul": {
    padding: "0.65rem",
    border: "1px solid #C4C4C4",
    borderRadius: "4px",

    "& li": {
      display: "flex",
      justifyContent: "space-between",
      padding: "0 0 0.5rem",
      fontSize: "0.875rem",

      "&:last-child": {
        paddingBottom: "0",
      },

      "& .title": {
        color: "#454242",
        fontWeight: 500,
      },

      "& .details": {
        color: "#847F7F",
        fontWeight: 400,
      },
    },
  },

  //   Styles for screen of width 767.9px and below
  "@media (max-width:767.9px)": {},
}));

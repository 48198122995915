import { createTheme } from "@mui/material/styles";

export let theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },

  palette: {
    background: {
      default: "#e5e5e5",
    },

    primary: {
      light: "rgba(255, 83, 73,0.85)",
      main: "#FF5349",
      //   dark: "",
      //   contrastText: "#ffffff",
    },
  },

  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            // backgroundColor: "rgba(255, 83, 73, 0.3)", // Change this color as needed
          },
        },
      },
    },
  },
});

import { apiHeaders, urlConfig, getSessionData } from "../../config";
import axios from "axios";
import { ToastMessage, TOASTER_TYPE } from "../../components";
import { removeBase64StringImgFromCart } from "../../utils/helper";

const handleError = () => {
  ToastMessage(TOASTER_TYPE.ERROR, "An error occured, Please try again");
};

export const initializeOpenSession: any = async (payload: any) => {
  let sessionData: any;

  try {
    sessionData = getSessionData();

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": payload.name,
        "X-PYC-UserURN": payload.conURN,
      },
    };

    const res = await axios.post(
      `${urlConfig.BASE_URL}/wallets/${sessionData.tenant}/v1/Sessions`,
      {
        uniqueReferenceNumber: sessionData.uniqueReferenceNumber,
        merchantUniqueReferenceNumber:
          sessionData.merchantUniqueReferenceNumber,
        cart: removeBase64StringImgFromCart(sessionData.cart), // convert cart price to kobo
        consumerUniqueReferenceNumber: payload.conURN,
        walletUniqueReferenceNumber: payload.wURN,
      },
      config
    );

    if (res.status === 409) {
      // Similar uniqueRef but likely that cart item has beeen updated
      // Perform a PUT request
      updateOpenSession().then((amendResponse: any) => {
        if (amendResponse.status === 200 || amendResponse.status === 201) {
          // Recalling open seession after unique ref is amended
          initializeOpenSession();
        }
      });
    }

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const updateOpenSession: any = async () => {
  let sessionData: any;

  try {
    sessionData = getSessionData();

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": sessionData.name,
        "X-PYC-UserURN": sessionData.conURN,
      },
    };

    const res = await axios.put(
      `${urlConfig.BASE_URL}/wallets/${sessionData.tenant}/v1/Sessions/${sessionData.uniqueReferenceNumber}`,
      {
        cart: sessionData.cart, // Update cart for an existing session
      },
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const getAllRewards: any = async () => {
  let sessionData: any;

  try {
    sessionData = getSessionData();

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": sessionData.name,
        "X-PYC-UserURN": sessionData.conURN,
      },
    };

    const paramData: any = {
      NonExpiredOnly: false,
      PageSize: 50,
      WalletUniqueReferenceNumber: sessionData.wURN,
    };

    const params = new URLSearchParams();

    for (const key in paramData) {
      if (paramData.hasOwnProperty(key)) {
        params.set(key, paramData[key].toString());
      }
    }

    const res = await axios.get(
      `${urlConfig.BASE_URL}/wallets/${sessionData.tenant}/v1/Rewards?${params}`,
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const getCartItemsInSession: any = async () => {
  let sessionData: any;

  try {
    sessionData = getSessionData();

    const config = {
      headers: apiHeaders,
    };

    const res = await axios.get(
      `${urlConfig.BASE_URL}/wallets/${sessionData.tenant}/v1/Sessions/${sessionData.uniqueReferenceNumber}`,
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

// Transaction and Payment
export const initializeTransaction: any = async (payload: any) => {
  let sessionData: any;

  try {
    sessionData = getSessionData();

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": sessionData.name,
        "X-PYC-UserURN": sessionData.conURN,
      },
    };

    const res = await axios.post(
      `${urlConfig.BASE_URL}/payments/${sessionData.tenant}/v1/Transactions`,
      {
        ...payload,
        paymentItemUniqueReferenceNumber: sessionData.uniqueReferenceNumber,
        customerUniqueReferenceNumber: sessionData.tenant,
      },
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const verifyTransaction: any = async (payload: any) => {
  let sessionData: any;

  try {
    sessionData = getSessionData();

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": sessionData.name,
        "X-PYC-UserURN": sessionData.conURN,
      },
    };

    const res = await axios.post(
      `${urlConfig.BASE_URL}/payments/${sessionData.tenant}/v1/Transactions/${sessionData.uniqueReferenceNumber}/verifications`,
      {
        ...payload,
      },
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const getPaymentGateway: any = async (data: any) => {
  let sessionData: any;

  const headerDetails = data.queryKey[1];

  try {
    sessionData = getSessionData();
    const token = localStorage.getItem("ID_T");

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": sessionData.name || headerDetails.name,
        "X-PYC-UserURN": sessionData.conURN || headerDetails.conURN,
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.get(
      `${urlConfig.BASE_URL}/payments/${sessionData.tenant}/v1/GatewaySettings/${sessionData.merchantUniqueReferenceNumber}`,
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const createCartToken: any = async (payload: any) => {
  let sessionData: any;

  try {
    sessionData = getSessionData();

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": sessionData.name,
        "X-PYC-UserURN": sessionData.conURN,
      },
    };

    const res = await axios.post(
      `${urlConfig.BASE_URL}/wallets/${sessionData.tenant}/v1/CardTokens`,
      {
        ...payload,
        consumerUniqueReferenceNumber: sessionData.conURN,
        walletUniqueReferenceNumber: sessionData.wURN,
      },
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const replaceCartToken: any = async (payload: any) => {
  let sessionData: any;

  try {
    sessionData = getSessionData();

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": sessionData.name,
        "X-PYC-UserURN": sessionData.conURN,
      },
    };

    const res = await axios.put(
      `${urlConfig.BASE_URL}/wallets/${sessionData.tenant}/v1/CardTokens/${payload.uniqueReferenceNumber}`,
      {
        ...payload,
        consumerUniqueReferenceNumber: sessionData.conURN,
        walletUniqueReferenceNumber: sessionData.wURN,
        merchantUniqueReferenceNumber:
          sessionData.merchantUniqueReferenceNumber,
      },
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const removeCartToken: any = async (payload: any) => {
  let sessionData: any;

  try {
    sessionData = getSessionData();

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": sessionData.name,
        "X-PYC-UserURN": sessionData.conURN,
      },
    };

    const paramData = {
      WalletUniqueReferenceNumber: sessionData.wURN,
      GatewayCustomerId: payload.GatewayCustomerId,
    };

    const params = new URLSearchParams({
      ...paramData,
    });

    const res = await axios.delete(
      `${urlConfig.BASE_URL}/wallets/${sessionData.tenant}/v1/CardTokens/${payload.uniqueReferenceNumber}/?${params}&CustomerEmailAddress=${payload.CustomerEmailAddress}`,
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const getCartToken: any = async (data: any) => {
  let sessionData: any;

  const headerDetails = data.queryKey[1];

  try {
    sessionData = getSessionData();

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": sessionData.name || headerDetails.name,
        "X-PYC-UserURN": sessionData.conURN || headerDetails.conURN,
      },
    };

    const paramData = {
      CustomerUniqueReferenceNumber: sessionData.tenant,
      MerchantUniqueReferenceNumber: sessionData.merchantUniqueReferenceNumber,
      ConsumerUniqueReferenceNumber: sessionData.conURN || headerDetails.conURN,
    };

    const params = new URLSearchParams({
      ...paramData,
    });

    const res = await axios.get(
      `${urlConfig.BASE_URL}/wallets/${sessionData.tenant}/v1/CardTokens/?${params}`,
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const getCartTokenWithUrn: any = async (data: any) => {
  let sessionData: any;

  const payload = data.queryKey[1];

  try {
    sessionData = getSessionData();

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": sessionData.name || payload.name,
        "X-PYC-UserURN": sessionData.conURN || payload.conURN,
      },
    };

    const res = await axios.get(
      `${urlConfig.BASE_URL}/wallets/${sessionData.tenant}/v1/CardTokens/${payload.uniqueReferenceNumber}`,
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const getEarnableRewards: any = async () => {
  let sessionData: any;

  try {
    sessionData = getSessionData();

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": sessionData.name,
        "X-PYC-UserURN": sessionData.conURN,
      },
    };

    const body = {
      merchantUniqueReferenceNumber: sessionData.merchantUniqueReferenceNumber,
      consumerUniqueReferenceNumber: sessionData.conURN,
      customerUniqueReferenceNumber: sessionData.tenant,
      walletUniqueReferenceNumber: sessionData.wURN,
      cart: removeBase64StringImgFromCart(sessionData.cart),
    };

    const res = await axios.post(
      `${urlConfig.BASE_URL}/wallets/${sessionData.tenant}/v1/Sessions/${sessionData.uniqueReferenceNumber}/rewards`,
      body,
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const checkoutToPay: any = async (payload: any) => {
  let sessionData: any;

  try {
    sessionData = getSessionData();
    const token = localStorage.getItem("ID_T");
    console.log(token);

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": sessionData.name,
        "X-PYC-UserURN": sessionData.conURN,
        Authorization: `Bearer ${token}`,
      },
    };

    const body = {
      ...payload,
      merchantUniqueReferenceNumber: sessionData.merchantUniqueReferenceNumber,
      consumerUniqueReferenceNumber: sessionData.conURN,
      walletUniqueReferenceNumber: sessionData.wURN,
      cart: removeBase64StringImgFromCart(sessionData.cart),
    };

    const res = await axios.post(
      `${urlConfig.BASE_URL}/wallets/${sessionData.tenant}/v1/Sessions/${sessionData.uniqueReferenceNumber}/checkouts`,
      body,
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};

export const getPoints: any = async () => {
  let sessionData: any;

  try {
    sessionData = getSessionData();

    const config = {
      headers: {
        ...apiHeaders,
        "X-PYC-UserName": sessionData.name,
        "X-PYC-UserURN": sessionData.conURN,
      },
    };

    const paramData = {
      CustomerUniqueReferenceNumber: sessionData.tenant,
      MerchantUniqueReferenceNumber: sessionData.merchantUniqueReferenceNumber,
      ConsumerUniqueReferenceNumber: sessionData.conURN,
    };

    const params = new URLSearchParams({
      ...paramData,
    });

    const res = await axios.get(
      `${urlConfig.BASE_URL}/loyalties/${sessionData.tenant}/v1/Points?${params}`,
      config
    );

    return res;
  } catch (err: any) {
    if (err.response?.status === 500) handleError();
    return err;
  }
};


import { styled } from "@mui/material";
import Accordion from "@mui/material/Accordion";

export const FrameBox = styled(Accordion)(({ theme }) => ({
  borderRadius: "6px !important",
  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.14) !important",
  padding: "1.25rem !important",

  "& .headerSection": {
    display: "flex",
    alignItems: "flex-start",

    "& .checkbox": {
      width: "18px",
      height: "18px",
      marginTop: "0.1rem",
    },

    "& .MuiCheckbox-root ": {
      color: "#C4C4C4",
      borderRadius: "2px",
    },

    "& .Mui-checked": {
      color: "#00AE97 !important",
    },

    "& .icon": {
      marginLeft: "0.5rem",
      width: "22px",
      height: "22px",
    },
  },

  "& .MuiAccordionSummary-root": {
    padding: "0 !important",
    minHeight: "unset !important",
    alignItems: "baseline",
    width: "100%",
    position: "relative",
  },

  "& .MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded ":
    {
      margin: "0",
      minHeight: "unset !important",
    },

  "& .headerContainer": {
    display: "flex",
    alignItems: "flex-start",

    "& .titleHolder": {
      display: "flex",
      alignItems: "center",

      "& .title": {
        display: "block",
        marginBottom: "0",
        color: "#150200",
        fontSize: "1.125rem",
        fontWeight: 500,
      },
    },

    "& .subTitleAmount": {
      fontSize: "0.95rem",

      "& span": {
        fontWeight: 400,
        display: "inline-block",

        "&:last-child": {
          fontWeight: 600,
          color: "#150200",
          marginLeft: "auto",
          position: "absolute",
          right: 0,
        },
      },
    },

    "& .details": {
      display: "block",
      margin: "0.3rem 0 0",
      color: "#847F7F",
      fontSize: "0.875rem",
      fontFamily: "Raleway",
      // marginLeft: "30px",
    },
  },

  "& .MuiAccordionDetails-root": {
    padding: "0.75rem 0 0 !important",
    margin: "0 0rem 0 51px",
    // maxHeight: "400px",
    // overflowY: "auto",
  },

  "& .detailWithIconMarginLeft": {
    margin: "0 0.5rem 0 26px",
  },

  "& .detailNoMarginLeft": {
    margin: "0 !important",
  },

  "@media (max-width:1199.9px)": {
    padding: "1rem !important",
  },

  //   Styles for screen of width 767.9px and below
  "@media (max-width:991.9px)": {
    "& .MuiAccordionDetails-root,  .detailWithIconMarginLeft, .detailNoMarginLeft":
      {
        padding: "1rem 0 0 !important",
        margin: "0 !important",
      },
  },
}));

import React from "react";
import { CartCardBox } from "./CartCard.style";
import ProductBoxImg from "../../../../assets/images/product-box.png";
import { RewardTypes, RewardRuleEffects } from "../../../../types";
import { NumericFormat } from "react-number-format";
import { convertPriceToNaira } from "../../../../utils/helper";

interface ICartProps {
  name: string;
  image: string;
  quantity: string;
  sku: string;
  price: string;
  newPrice: string;
  newQuantity: string;
  rewardType: string;
  discount: any;
  gateWayDetails: any;
}

export const CartCard: React.FC<ICartProps> = ({
  name,
  image,
  price,
  newPrice,
  newQuantity,
  rewardType,
  quantity,
  sku,
  discount,
  gateWayDetails
}) => {
  const calculatePriceAndQty = (price: any, qty: any) => {
    return Number(price) * Number(qty);
  };

  const getRewardText = (reward_type: string) => {
    let rewardText: string = "";

    if (reward_type === RewardRuleEffects.AddFreeItem) {
      rewardText = "Buy 2 get 1 free";
    }

    if (reward_type === RewardRuleEffects.AddDiscount) {
      rewardText = `SaveNMore - [ ${discount}% off ]`;
    }

    return `${rewardText}`;
  };

  return (
    <CartCardBox>
      <div className={"cartCard"}>
        <div className={"imgHolder"}>
          <img src={image} className="img-fluid" alt="..." />
        </div>

        <div className={"detailSection"}>
          <p className={"text mb-0"}>{name}</p>
          <div className="code">SKU Code : {sku}</div>

          <div className={"quantity"}>Qty : {newQuantity}</div>

          {/* {rewardType && (
            <div className="rewardSection">
              <div className="rewardBadge">Reward</div>
              <div className="mx-2">:</div>
              <div className="value">{getRewardText(rewardType)}</div>
            </div>
          )} */}

          {rewardType && (
            <div className="rewardAppliedBadge">
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 3.04688V4.6875H9.14063C9.46511 4.6875 9.78231 4.59128 10.0521 4.41101C10.3219 4.23073 10.5322 3.9745 10.6564 3.67472C10.7805 3.37493 10.813 3.04506 10.7497 2.72681C10.6864 2.40856 10.5302 2.11622 10.3007 1.88678C10.0713 1.65733 9.77895 1.50108 9.4607 1.43777C9.14245 1.37447 8.81257 1.40696 8.51279 1.53114C8.213 1.65531 7.95677 1.86559 7.7765 2.13539C7.59622 2.40519 7.5 2.72239 7.5 3.04688V3.04688ZM7.5 3.04688V4.6875H5.85938C5.53489 4.6875 5.21769 4.59128 4.94789 4.41101C4.67809 4.23073 4.46781 3.9745 4.34364 3.67472C4.21946 3.37493 4.18697 3.04506 4.25027 2.72681C4.31358 2.40856 4.46983 2.11622 4.69928 1.88678C4.92872 1.65733 5.22106 1.50108 5.53931 1.43777C5.85756 1.37447 6.18743 1.40696 6.48722 1.53114C6.787 1.65531 7.04323 1.86559 7.22351 2.13539C7.40378 2.40519 7.5 2.72239 7.5 3.04688V3.04688Z"
                  stroke="#00AE97"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M12.1875 4.6875H2.8125C2.29473 4.6875 1.875 5.10723 1.875 5.625V7.03125C1.875 7.54902 2.29473 7.96875 2.8125 7.96875H12.1875C12.7053 7.96875 13.125 7.54902 13.125 7.03125V5.625C13.125 5.10723 12.7053 4.6875 12.1875 4.6875Z"
                  stroke="#00AE97"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.1875 7.96875V12.1875C12.1875 12.5605 12.0393 12.9181 11.7756 13.1819C11.5119 13.4456 11.1542 13.5937 10.7812 13.5938H4.21875C3.84579 13.5937 3.4881 13.4456 3.22438 13.1819C2.96066 12.9181 2.8125 12.5605 2.8125 12.1875V7.96875M7.5 4.6875V13.5938"
                  stroke="#00AE97"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <>{getRewardText(rewardType)}</>
            </div>
          )}
        </div>

        <div className={"priceContainer"}>
          {newPrice !== price && (
            <div className={"oldPrice"}>
              <NumericFormat
                value={convertPriceToNaira(
                  calculatePriceAndQty(price, quantity)
                )}
                displayType={"text"}
                thousandSeparator={true}
                prefix={gateWayDetails?.currencySymbol}
              />
              {/* {calculatePriceAndQty(price, quantity)} */}
            </div>
          )}

          <div className={"price"}>
            <NumericFormat
              value={convertPriceToNaira(
                calculatePriceAndQty(newPrice, quantity)
              )}
              displayType={"text"}
              thousandSeparator={true}
              prefix={gateWayDetails?.currencySymbol}
            />
            {/* &#8358;
            {calculatePriceAndQty(newPrice, quantity)} */}
          </div>
        </div>
      </div>
    </CartCardBox>
  );
};

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import paycurra from "../../../../assets/logo/logo-paycurra.png";
import { FooterBox } from "./footer.styles";
import { IgIcon, FbIcon, TwitterIcon } from "../../../../assets/svg";
import {
  FaInstagram,
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";

export const Footer = () => {
  const navigate = useNavigate();
  const paycurra_base_url = "https://www.paycurra.com";

  const handleRoute = (link: string, name: string) => {
    window.location.href = `${paycurra_base_url}/${link}`;
  };

  

  return (
    <FooterBox>
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-5 col-12">
            <div className="d-inline-flex flex-column logo">
              <img src={paycurra} alt="" className="img-fluid" />
              <small className="">
                Commerce platform by{" "}
                <a href="https://www.cognitivelabs.co" target="_blank">
                  Cognitive Labs
                </a>
              </small>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <ul className="footerList">
              <li className="title">Products</li>
              <li className="item">
                <a href={"https://merchants.paycurra.com"} target="_blank">
                  Paycurra Merchant
                </a>
              </li>
              <li className="item">
                <a href="https://checkout.paycurra.com" target="_blank">
                  Paycurra Checkout
                </a>
              </li>
              <li className="item">
                <a href={`${paycurra_base_url}/download-app`} target="_blank">
                  Paycurra Mobile
                </a>
              </li>
            </ul>

            <div className="socialContainer">
              <h5 className="title">Connect with us</h5>
              <div className="iconHolder">
                <a href="https://www.instagram.com/paycurra" target="_blank">
                  <FaInstagram />
                </a>
                <a href="https://www.facebook.com/paycurra" target="_blank">
                  <FaFacebookF />
                </a>
                {/* <a href="#" target="_blank">
                  <FaLinkedinIn />
                </a> */}
                <a href="https://www.twitter.com/paycurra" target="_blank">
                  <FaTwitter />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 col-6">
            <ul className="footerList">
              <li className="title">Company</li>
              <li
                className="item"
                onClick={() => handleRoute("features", "Features")}
              >
                Features
              </li>
              {/* <li className="item">Pricing</li> */}
              {/* <li className="item">Insights</li> */}
              <li className="item">
                <a href={`${paycurra_base_url}/integration`} target="_blank">
                  Integration{" "}
                </a>
              </li>
              {/* <li className="item">Paycurra</li> */}
            </ul>
          </div>
        </div>

        <div className="legalSection">
          <p className="mb-0 copyText">
            &copy; {new Date().getFullYear()} All rights reserved.
          </p>

          <div className="legalLinks">
            <a
              href={`${paycurra_base_url}/privacy-policy`}
              target="_blank"
              className=""
            >
              Privacy policy
            </a>
            <a
              href={`${paycurra_base_url}/terms-and-conditions`}
              target="_blank"
              className=""
            >
              Terms and conditions
            </a>
            <a href={`${paycurra_base_url}/faq`} target="_blank" className="">
              FAQ
            </a>
          </div>
        </div>
      </div>
    </FooterBox>
  );
};

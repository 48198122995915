import * as React from "react";
import { PointCardBox } from "./PointCard.style";
import { getPoints } from "../../../../services/api";

interface IProps {
  isPointChecked: boolean;
  handleChange: (arg: any) => void;
  totalAmount: number;
  updatedCartTotal: number;
  userPointDetails: any;
}

export const PointCard: React.FC<IProps> = ({
  isPointChecked = false,
  handleChange,
  totalAmount,
  userPointDetails,
  updatedCartTotal,
}) => {
  const [hasValue, setHasValue] = React.useState<boolean>(false);
  const [invalidPoint, setInvalidPoint] = React.useState<any>({
    isError: false,
    errorMessage: "",
  });
  const pointInputRef = React.useRef<any>(null);

  /**
   * This Effect runs to update the point
   * when reward is applied after a point
   * subtract the excess point from total amount to checkout
   */
  React.useEffect(() => {
    if (pointInputRef && updatedCartTotal < 0) {
      const updatedPoint =
        Number(pointInputRef.current.value) + updatedCartTotal;
      // update point across board
      updatePointGlobally(updatedPoint);
    }
  }, [updatedCartTotal]);

  /**
   * Run this effect when point is unchecked
   * Reset point value to Zero
   */
  React.useEffect(() => {
    if (!isPointChecked) {
      updatePointGlobally(0);
    }
  }, [isPointChecked]);

  const updatePointGlobally = (updatedPoint: number) => {
    // update input value
    pointInputRef.current.value = updatedPoint === 0 ? "" : updatedPoint;
    // update parent point value
    handleChange(updatedPoint);
  };

  const validatePointMinAndMaxValue = (event: any, maxNumber: number) => {
    let value = event.target.value;
    const minValue = 0;
    const maxValue = maxNumber;

    if (value < minValue) {
      setInvalidPoint({
        isError: true,
        errorMessage: "Invalid input, enter value above 0 points",
      });
      event.target.value = "";
    } else if (value > maxValue) {
      setInvalidPoint({
        isError: true,
        errorMessage:
          "Invalid input, Points cannot be more than your cart total",
      });
      event.target.value = event.target.value.slice(0, -1);
    } else if (value > userPointDetails?.currentBalance) {
      setInvalidPoint({
        isError: true,
        errorMessage: `Insufficient points, enter point less than ${userPointDetails?.currentBalance}`,
      });
      event.target.value = event.target.value.slice(0, -1);
    } else {
      setInvalidPoint({
        isError: false,
        errorMessage: "",
      });
    }
  };

  return (
    <PointCardBox>
      <div className="card">
        <h3 className="title mb-0">Jumia Points </h3>
        <p className="balance mb-0">
          Balance :{" "}
          {userPointDetails?.currentBalance
            ? userPointDetails?.currentBalance
            : 0}
        </p>
        <div className="inputHolder mt-2">
          <input
            ref={pointInputRef}
            type={"number"}
            placeholder="Enter Points you want to redeem"
            required={isPointChecked}
            autoFocus={isPointChecked}
            onChange={(e) => {
              e.target.value.length >= 1
                ? setHasValue(true)
                : setHasValue(false);

              // point validation
              validatePointMinAndMaxValue(e, totalAmount);

              // Sending point to parentr
              handleChange(e.target.value);
            }}
            readOnly={!isPointChecked}
            pattern="[0-9]"
          />
          {invalidPoint.isError && (
            <small className="errorMessage mt-1">
              {invalidPoint.errorMessage}
            </small>
          )}

          {isPointChecked && !hasValue && (
            <small className="errorMessage mt-1">
              Please enter point to apply
            </small>
          )}
        </div>
      </div>
    </PointCardBox>
  );
};

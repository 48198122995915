const env = process.env;

const dev = {
  BASE_URL: env.REACT_APP_BASE_URL,
  SUBSCRIPTION_KEY: env.REACT_APP_SUBSCRIPTION_KEY,
  B2C_CLIENT_ID: env.REACT_APP_B2C_CLIENT_ID,
  B2C_AUTHORITY: env.REACT_APP_B2C_AUTHORITY,
  B2C_KNOW_AUTHORITIES: [env.REACT_APP_B2C_KNOW_AUTHORITIES],
  B2C_REDIRECT_URI: env.REACT_APP_B2C_REDIRECT_URI,
  B2C_POST_LOGOUT_REDIRECT_URI: env.REACT_APP_B2C_POST_LOGOUT_REDIRECT_URI,
  B2C_SCOPES: env.REACT_APP_B2C_SCOPES,
  B2C_SIGN_UP_IN_AUTHORITY: env.REACT_APP_B2C_SIGN_UP_IN_AUTHORITY,
  B2C_FORGOT_PASSWORD_AUTHORITY: env.REACT_APP_B2C_FORGOT_PASSWORD_AUTHORITY,
  B2C_EDIT_PROFILE_AUTHORITY: env.REACT_APP_B2C_EDIT_PROFILE_AUTHORITY,
  B2C_AUTHORITY_DOMAIN: env.REACT_APP_B2C_AUTHORITY_DOMAIN,
  
};

const prod = {
  BASE_URL: "https://clbs-prd-eun-pyc-apim.azure-api.net",
  SUBSCRIPTION_KEY: "c9c46511e2eb49089460df4a7208a816",
  B2C_CLIENT_ID: "289811f5-6689-4271-a0ac-81c523dabf6a",
  B2C_AUTHORITY:
    "https://auth.paycurra.com/e6fef669-16af-426e-86ca-a0b447d7c115/B2C_1A_PYC_CONSMR_CHKO_SIGNIN",
  B2C_KNOW_AUTHORITIES: ["auth.paycurra.com"],
  B2C_REDIRECT_URI: "https://checkout.paycurra.com/loginComplete",
  B2C_POST_LOGOUT_REDIRECT_URI: "https://checkout.paycurra.com/logout",
  B2C_SCOPES:
    "https://clbsprd01eunpyccnsmr.onmicrosoft.com/api://identityexperienceframework/user_impersonation openid",
  B2C_SIGN_UP_IN_AUTHORITY:
    "https://auth.paycurra.com/e6fef669-16af-426e-86ca-a0b447d7c115/B2C_1A_PYC_CONSMR_SIGNUP_SIGNIN",
  B2C_FORGOT_PASSWORD_AUTHORITY:
    "https://auth.paycurra.com/e6fef669-16af-426e-86ca-a0b447d7c115/B2C_1A_PYC_CONSMR_PASSWORDRESET",
  B2C_EDIT_PROFILE_AUTHORITY:
    "https://auth.paycurra.com/e6fef669-16af-426e-86ca-a0b447d7c115/B2C_1A_PYC_CONSMR_PROFILEEDIT",
  B2C_AUTHORITY_DOMAIN: "auth.paycurra.com",
};

export const urlConfig = env.NODE_ENV === "development" ? dev : prod;

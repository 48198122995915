import { styled } from "@mui/material";

export const NavBox = styled("nav")(({ theme }) => ({
    position: "relative",
    background: "transparent",
  
    "& .iconHolder": {
      position: "absolute",
      top: "0",
      right: "-2rem",
      zIndex: 500000,
      display: "flex",
      marginLeft: "auto",
      cursor: "pointer",
      padding: "0.45rem",
      borderRadius: "50%",
    },
  }));
  
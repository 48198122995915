import { styled } from "@mui/material";

export const RadioCardBox = styled("div")(({ theme }) => ({
  "& .selected": {
    border: "1px solid #00AE97",
  },

  "& .checkBtn": {
    width: "16px",
    height: "16px",
    minWidth: "16px",
    minHeight: "16px",
    borderRadius: "50%",
    border: "1px solid #C0BFBF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& svg": {
      height: "13px",
    },
  },

  "& .checkedBtn": {
    border: "none",
    backgroundColor: "#00AE97",
  },

  "& .card": {
    padding: "0.5rem",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "row",
    cursor: "pointer",
  },

  "& .title": {
    fontSize: "0.875rem",
    fontWeight: 500,
    color: "#150200",
  },

  "& .balance": {
    fontSize: "0.75rem",
    fontWeight: 500,
    color: "#847F7F",
  },

  //   Styles for screen of width 767.9px and below
  "@media (max-width:767.9px)": {},
}));

import { PaymentSuccessBox } from "./PaymentSuccess.style";
import { PaycurraLogo, PCButton } from "../../components";
import { useLocation } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { useMsal } from "@azure/msal-react";
import { msalConfig } from "../../config";
import * as React from "react";
import { NumericFormat } from "react-number-format";
import { convertPriceToNaira } from "../../utils/helper";

export const PaymentSuccess = () => {
  const { state } = useLocation();
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = React.useState<boolean>(false);


  const closeIframe = async () => {
    setLoading(true);
    const logoutRequest = {
      accounts: instance.getAccountByHomeId(accounts[0].homeAccountId),
      postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
    };

    // Handle msal loogout
    instance.logoutRedirect(logoutRequest).then((res) => {
      console.log("Log out complete", res);
    });
  };

  const removeDash = (text: string) => {
    if (!text) return "";
    return text.split("-").join("");
  };

  // console.log(state);

  const cheeckoutDetails =
    state && state?.paymentDetails?.data?.cardTokenChargedResult;
  const gateWayDetails = state && state?.gateWayDetails;

  return (
    <section className="d-flex justify-content-center align-items-center pt-5">
      <div className="col-xxl-4 col-xl-6 col-md-8 mt-5">
        <PaymentSuccessBox>
          <PaycurraLogo />

          <div className="paymentContainer">
            <svg
              width="82"
              height="82"
              viewBox="0 0 82 82"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M41 0.375C18.5996 0.375 0.375 18.5996 0.375 41C0.375 63.4004 18.5996 81.625 41 81.625C63.4004 81.625 81.625 63.4004 81.625 41C81.625 18.5996 63.4004 0.375 41 0.375ZM62.1426 27.3848L35.8926 58.6348C35.6046 58.9777 35.2463 59.2548 34.842 59.4472C34.4376 59.6396 33.9966 59.7429 33.5488 59.75H33.4961C33.0581 59.7498 32.625 59.6576 32.2249 59.4793C31.8249 59.3009 31.4667 59.0405 31.1738 58.7148L19.9238 46.2148C19.6381 45.9118 19.4159 45.5547 19.2701 45.1646C19.1244 44.7744 19.0581 44.3591 19.0752 43.9429C19.0923 43.5268 19.1924 43.1183 19.3696 42.7414C19.5468 42.3645 19.7976 42.0268 20.1072 41.7482C20.4168 41.4696 20.7789 41.2557 21.1723 41.1191C21.5658 40.9824 21.9825 40.9258 22.3982 40.9525C22.8138 40.9793 23.2199 41.0888 23.5926 41.2747C23.9653 41.4606 24.297 41.7192 24.5684 42.0352L33.4141 51.8633L57.3574 23.3652C57.8945 22.7443 58.6544 22.3596 59.4727 22.2944C60.2911 22.2292 61.1023 22.4887 61.7309 23.0167C62.3596 23.5448 62.7552 24.299 62.8322 25.1164C62.9092 25.9337 62.6615 26.7486 62.1426 27.3848Z"
                fill="#00AE97"
              />
            </svg>

            <h3 className="title">
              You paid{" "}
              <strong>
                <NumericFormat
                  value={convertPriceToNaira(state?.rawCartTotal)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={gateWayDetails?.currencySymbol}
                />
              </strong>
            </h3>

            <p className="details">to {gateWayDetails?.companyName}</p>

            <h5 className="detailTitle">Details</h5>

            <div className="d-flex justify-content-between">
              <div className="sectionTitle mb-1">Transaction Payment</div>
              <div className="value">
                <NumericFormat
                  value={convertPriceToNaira(state?.rawCartTotal)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={gateWayDetails?.currencySymbol}
                />
              </div>
            </div>

            <div className="infoDetails mb-4">
              You will see this transaction on your statement as PAYCURRA{" "}
              {gateWayDetails?.companyName}
            </div>

            {/* <hr /> */}

            <h6 className="sectionTitle">Purchase Details</h6>
            <ul className="transactDetailsGroup">
              <li className="detailsList">
                <div className="name">Transaction ID</div>
                <div className="value text-uppercase">
                  {removeDash(
                    state?.paymentDetails?.data?.sessionUniqueReferenceNumber
                  ).substring(0, 12)}
                </div> 
              </li>
              <li className="detailsList">
                <div className="name">Payment Date </div>
                <div className="value">
                  {cheeckoutDetails?.transactionDate ? (
                    <>
                      {format(
                        parseISO(cheeckoutDetails?.transactionDate),
                        "dd MMM, yyyy, hh:mm aaaa"
                      )}
                    </>
                  ) : (
                    <>{format(new Date(), "dd MMM, yyyy, hh:mm aaaa")}</>
                  )} 
                </div>
              </li>
              <li className="detailsList">
                <div className="name">We'll send your receipt to:</div>
                <div className="value">
                  consumer@mail.com
                  {/* {format(
                    parseISO(state?.paymentDetails?.createdAt),
                    "dd MMM, yyyy, hh:mm aaaa"
                  )} */}
                </div>
              </li>
            </ul>
          </div>

          <div className="d-flex justify-content-center">
            <PCButton
              isFullWidth={false}
              text="Return to Merchant"
              click={closeIframe}
              loading={loading}
            />
          </div>
        </PaymentSuccessBox>
      </div>
    </section>
  );
};

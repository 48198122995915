import { AddCardPaymentBox } from "./AddPaymentMethod.style";
import { PCInput } from "../Inputs";
import { PatternFormat } from "react-number-format";
import VisaCardLogo from "../../assets/images/visa.png";
import MasterCardLogo from "../../assets/images/mastercard-logo.png";

export const AddCardPayment = () => {
  return (
    <AddCardPaymentBox>
      <h4 className="title">Add a payment method </h4>
      <p className="details">
        Make sure the debit card belongs to you, by adding your card a
        processing of NGN 100.00 will be deducted from your account.
      </p>

      <div className="cardDetailsContainer">
        <div className="payStackContainer">
          {/* <svg
            className="logo"
            width="24"
            height="27"
            viewBox="0 0 24 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.4836 0H1.25782C0.578597 0 0 0.645663 0 1.43169V3.98626C0 4.77228 0.578597 5.41794 1.25782 5.41794H21.4836C22.1879 5.41794 22.7414 4.77228 22.7665 3.98626V1.45976C22.7665 0.645661 22.1879 0 21.4836 0ZM21.4836 14.1765H1.25782C0.930787 14.1765 0.603754 14.3169 0.377346 14.5976C0.125782 14.8783 0 15.2152 0 15.6082V18.1627C0 18.9488 0.578597 19.5944 1.25782 19.5944H21.4836C22.1879 19.5944 22.7414 18.9768 22.7665 18.1627V15.6082C22.7414 14.7941 22.1879 14.1765 21.4836 14.1765ZM12.6537 21.2507H1.25782C0.930787 21.2507 0.603754 21.3911 0.377346 21.6718C0.150938 21.9525 0 22.2894 0 22.6824V25.237C0 26.023 0.578597 26.6686 1.25782 26.6686H12.6285C13.3329 26.6686 13.8863 26.023 13.8863 25.265V22.7105C13.9115 21.8683 13.358 21.2226 12.6537 21.2507ZM22.7665 7.07421H1.25782C0.930787 7.07421 0.603754 7.21457 0.377346 7.49529C0.150938 7.77601 0 8.11288 0 8.50589V11.0605C0 11.8465 0.578597 12.4922 1.25782 12.4922H22.7414C23.4458 12.4922 23.9992 11.8465 23.9992 11.0605V8.50589C24.0244 7.71987 23.4458 7.10228 22.7665 7.07421Z"
              fill="#00C3F7"
            />
          </svg> */}
          <div></div>

          <div className="amountDetails">
            <div className="email">demo@paystack.com</div>
            <div className="pay">
              Pay
              <span>NGN 100</span>
            </div>
          </div>
        </div>

        <div className="cardTypeContainer">
          <div className="cardName">
            <input type={"radio"} />
            <span>Credit or Debit Card</span>
          </div>

          <div className="cardIcons">
            <img
              src={VisaCardLogo}
              className="img-fluid"
              alt="visa card logo"
              height={"17"}
            />

            <img
              src={MasterCardLogo}
              className="img-fluid"
              alt="mastercard logo"
              height={"20"}
            />
          </div>
        </div>

        {/* <h5 className="title">Enter card details to save</h5> */}

        <form className="formContainer">
          <div className="pc_form__group">
            <label className="pc_form__lebel">
              Card Number<span>*</span>
            </label>
            <PatternFormat
              className="form-control"
              format="#### #### #### ####"
              mask="_"
            />
          </div>

          <div className="row">
            <div className="pc_form__group col-6">
              <label className="pc_form__lebel">
                Expiry Date<span>*</span>
              </label>
              <PatternFormat className="form-control" format="##/##" />
            </div>

            <div className="pc_form__group col-6">
              <label className="pc_form__lebel">
                CVC / CVV<span>*</span>
              </label>
              <PatternFormat className="form-control" format="###" mask="_" />
            </div>
          </div>

          <div className="securityNote">
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_382_1450)">
                <path
                  d="M13.2812 17H3.71875C2.84042 17 2.125 16.2853 2.125 15.4062V7.96875C2.125 7.08971 2.84042 6.375 3.71875 6.375H13.2812C14.1596 6.375 14.875 7.08971 14.875 7.96875V15.4062C14.875 16.2853 14.1596 17 13.2812 17ZM3.71875 7.4375C3.42621 7.4375 3.1875 7.6755 3.1875 7.96875V15.4062C3.1875 15.6995 3.42621 15.9375 3.71875 15.9375H13.2812C13.5738 15.9375 13.8125 15.6995 13.8125 15.4062V7.96875C13.8125 7.6755 13.5738 7.4375 13.2812 7.4375H3.71875Z"
                  fill="#847F7F"
                />
                <path
                  d="M12.2188 7.4375C11.9255 7.4375 11.6875 7.1995 11.6875 6.90625V4.25C11.6875 2.49262 10.2574 1.0625 8.5 1.0625C6.74262 1.0625 5.3125 2.49262 5.3125 4.25V6.90625C5.3125 7.1995 5.0745 7.4375 4.78125 7.4375C4.488 7.4375 4.25 7.1995 4.25 6.90625V4.25C4.25 1.90612 6.15612 0 8.5 0C10.8439 0 12.75 1.90612 12.75 4.25V6.90625C12.75 7.1995 12.512 7.4375 12.2188 7.4375Z"
                  fill="#847F7F"
                />
                <path
                  d="M8.49989 12.0413C7.7186 12.0413 7.08322 11.406 7.08322 10.6247C7.08322 9.84338 7.7186 9.20801 8.49989 9.20801C9.28118 9.20801 9.91655 9.84338 9.91655 10.6247C9.91655 11.406 9.28118 12.0413 8.49989 12.0413ZM8.49989 10.2705C8.3051 10.2705 8.14572 10.4292 8.14572 10.6247C8.14572 10.8202 8.3051 10.9788 8.49989 10.9788C8.69468 10.9788 8.85405 10.8202 8.85405 10.6247C8.85405 10.4292 8.69468 10.2705 8.49989 10.2705Z"
                  fill="#847F7F"
                />
                <path
                  d="M8.5 14.1667C8.20675 14.1667 7.96875 13.9287 7.96875 13.6354V11.6875C7.96875 11.3942 8.20675 11.1562 8.5 11.1562C8.79325 11.1562 9.03125 11.3942 9.03125 11.6875V13.6354C9.03125 13.9287 8.79325 14.1667 8.5 14.1667Z"
                  fill="#847F7F"
                />
              </g>
              <defs>
                <clipPath id="clip0_382_1450">
                  <rect width="17" height="17" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Your transaction is secured with SSL encryption
          </div>

          <button className="btn addCardBtn">Add card </button>
        </form>
      </div>
    </AddCardPaymentBox>
  );
};

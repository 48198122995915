import { urlConfig } from "./url.config";

export const apiHeaders = {
  "Content-Type": "application/json",
  "Ocp-Apim-Subscription-Key": urlConfig.SUBSCRIPTION_KEY,
};

export const getSessionData = () => {
  let session_data;
  try {
    session_data = JSON.parse(localStorage.getItem("session_data") as string);
  } catch (e) {
    console.log(e);
  }

  return session_data;
};

import { NavBox } from "./Nav.styles";
import PaycurraLogo from "../../../../assets/images/logo/paycurra-logo-identity.svg";
import AvatarImg from "../../../../assets/images/avatar-pycu.png";
import * as React from "react";

interface INavProps {
  name: string;
}

export const Nav: React.FC<INavProps> = ({ name }) => {
  return (
    <NavBox>
      <img src={PaycurraLogo} className="img-fluid logo" alt="Paycurra Logo" />

      <div className="avatarContainer">
        <img src={AvatarImg} alt="Avatar" width={"30"} height={"30"} />
        <div className="textSection">
          <p className="mb-0 name">{name}</p>
        </div>
      </div>
    </NavBox>
  );
};

import * as React from "react";
import { CardDetailsBox } from "./CardDetails.style";
import {
  getCartToken,
  initializeTransaction,
  verifyTransaction,
  createCartToken,
  getPaymentGateway,
  replaceCartToken,
  getCartTokenWithUrn,
  removeCartToken,
} from "../../../../services/api";
import { Dialog } from "@mui/material";
import {
  ConfirmChangeCard,
  AddPaymentMethodSuccess,
  LoadStripe,
} from "./components";
import { Loader } from "../../../../components";
import { v4 as uuidv4 } from "uuid";
import { getSessionData } from "../../../../config";
import { ToastMessage, TOASTER_TYPE } from "../../../../components";
import { Elements } from "@stripe/react-stripe-js";
import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";

interface IProps {
  getCardDetails: any;
  userDetails: any;
}

export const CardDetails: React.FC<IProps> = ({
  getCardDetails,
  userDetails,
}) => {
  const [initializeResp, setInitializeResp] = React.useState<any>(null);
  const [openPaystack, setOpenPaystack] = React.useState<boolean>(false);
  const [openStripe, setOpenStripe] = React.useState<boolean>(false);
  const [openAddPaymentMethodSuccess, setOpenAddPaymentMethodSuccess] =
    React.useState<boolean>(false);
  const [openConfirmChange, setOpenConfirmChange] =
    React.useState<boolean>(false);
  const [loadingPayment, setLoadingPayment] = React.useState<boolean>(false);
  const [conflictUrn, setConflictUrn] = React.useState<any>(null);
  const [cardDetails, setCardDetails] = React.useState<any>(null);

  const {
    data: gw_data,
    isLoading: gw_loading,
    refetch: gw_refetch,
  } = useQuery<any>(
    [
      "gateWayDetails",
      {
        wURN: userDetails.sub,
        conURN: userDetails.extension_UserURN,
        name: userDetails.name,
      },
    ],
    getPaymentGateway,
    {
      enabled: !!userDetails, // Enable the query when id is truthy
      retry: 0,
      staleTime: Infinity,
    }
  );

  const {
    data: ct_data,
    isLoading: ct_loading,
    refetch: ct_refetch,
  } = useQuery<any>(
    [
      "cardToken",
      {
        wURN: userDetails.sub,
        conURN: userDetails.extension_UserURN,
        name: userDetails.name,
      },
    ],
    getCartToken,
    {
      enabled: !!userDetails, // Enable the query when id is truthy
      retry: 0,
      staleTime: Infinity,
    }
  );

  const {
    data: sct_data,
    isLoading: sct_loading,
    refetch: sct_refetch,
  } = useQuery<any>(
    [
      "getCartTokenWithUrn",
      {
        wURN: userDetails.sub,
        conURN: userDetails.extension_UserURN,
        name: userDetails.name,
        uniqueReferenceNumber: conflictUrn,
      },
    ],
    getCartTokenWithUrn,
    {
      enabled: !!conflictUrn, // Enable the query when id is truthy
      retry: 0,
      staleTime: Infinity,
    }
  );

  const onInitializeTransaction = useMutation(initializeTransaction, {
    onSuccess: (res: any) => {
      setOpenConfirmChange(false);
      localStorage.setItem("P_D", JSON.stringify(res.data));
      setInitializeResp(res.data);
      setOpenPaystack(true); // Launch paystack modal
      // ToastMessage(TOASTER_TYPE.SUCCESS, "Card token created");
    },
    onError: (error: unknown) => {
      if (axios.isAxiosError(error)) {
        console.log(error);
        setLoadingPayment(false);
        setOpenConfirmChange(false);
        // ToastMessage(TOASTER_TYPE.ERROR, error?.response?.statusText);
        // ToastMessage(TOASTER_TYPE.ERROR, error.response?.data?.message);
      }
    },
  });

  const onVerifyTransaction = useMutation(verifyTransaction, {
    onSuccess: (res: any) => {
      handleCreateCardToken(res.data);
      // ToastMessage(TOASTER_TYPE.SUCCESS, "Card token created");
    },
    onError: (error: unknown) => {
      if (axios.isAxiosError(error)) {
        console.log(error);
        // ToastMessage(TOASTER_TYPE.ERROR, error.response?.data?.message);
      }
    },
  });

  const onCreateCartToken = useMutation(createCartToken, {
    onSuccess: (res: any) => {
      if (res.status === 200 || res.status === 201) {
        console.log(res);
        setCardDetails(res.data.data);
        // ct_refetch();
      }

      if (res.response?.status === 409) {
        // console.log(res.response);
        setConflictUrn(res.response?.data?.conflictUniqueReferenceNumber);
        return;
      }
      ToastMessage(TOASTER_TYPE.SUCCESS, "Card token created");
    },
    onError: (error: unknown) => {
      if (axios.isAxiosError(error)) {
        console.log("card token error", error);
        // ToastMessage(TOASTER_TYPE.ERROR, error.response?.data?.message);
      }
    },
  });

  const onRemoveCartToken = useMutation(removeCartToken, {
    onSuccess: (res: any) => {
      if (res.status === 200 || res.status === 201) {
        ct_refetch();

        ToastMessage(TOASTER_TYPE.SUCCESS, "Card token removed successfully");
      }
    },
    onError: (error: unknown) => {
      if (axios.isAxiosError(error)) {
        console.log("card token error", error);
        ToastMessage(TOASTER_TYPE.ERROR, "An error occured, please try again");
      }
    },
  });

  const onReplaceCartToken = useMutation(replaceCartToken, {
    onSuccess: (res: any) => {
      if (res.status === 200 || res.status === 201) {
        ToastMessage(TOASTER_TYPE.SUCCESS, "Card token changed successfully");
        // ct_refetch();
      }

      console.log(res);
    },
    onError: (error: unknown) => {
      if (axios.isAxiosError(error)) {
        console.log("card token error", error);
        // ToastMessage(TOASTER_TYPE.ERROR, error.response?.data?.message);
      }
    },
  });

  React.useEffect(() => {
    if (userDetails) {
      gw_refetch();
      // ct_refetch();
    }
  }, [userDetails]);

  React.useEffect(() => {
    // Verify transaction Func
    const onVerifyFunction = (data: any) => {
      // localStorage.setItem("session_data", sessionData);
      handleVerifyTransaction(data);
      setOpenConfirmChange(false);
      setOpenPaystack(false);
    };

    // Listen for a message from the Paystack checkout page
    const messageListener = (event: any) => {
      if (event.data.event === "success") {
        onVerifyFunction(event.data.data);
      }
    };

    window.addEventListener("message", messageListener, false);

    // Clean up
    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, []);

  React.useEffect(() => {
    if ((ct_data && ct_data?.data) || (gw_data && gw_data?.data)) {
      ct_data?.data?.data.forEach((card_token: any) => {
        if (
          card_token &&
          card_token?.statuses &&
          card_token?.statuses[card_token?.statuses.length - 1]?.statusName ===
            "Active"
        ) {
          setCardDetails(card_token);
        } else {
          setCardDetails(null);
        }
      });

      getCardDetails({ card: ct_data?.data?.data[0], gateway: gw_data?.data });
    }
  }, [ct_data, gw_data]);

  React.useEffect(() => {
    if (conflictUrn) sct_refetch();
  }, [conflictUrn]);

  const handleAddPaystackCard = async () => {
    setLoadingPayment(true);

    const body = {
      paymentUniqueReferenceNumber: uuidv4(),
      amount: 10000, // In kobo
      customerEmail: userDetails.email,
      customerId: "null",
      paymentCurrency: "NGN",
      paymentMethod: "card",
    };

    onInitializeTransaction.mutate(body as any);
  };

  const handleVerifyTransaction = async (paystackResponse: any) => {
    const initialTransactionDetails = JSON.parse(
      localStorage.getItem("P_D") as string
    );
    const body = {
      paymentUniqueReferenceNumber:
        initialTransactionDetails.paymentUniqueReferenceNumber,
      paymentItemUniqueReferenceNumber:
        initialTransactionDetails.paymentItemUniqueReferenceNumber,
      paymentMethodVerification: true,
      paymentGatewayReferenceNumber: paystackResponse.reference,
      customerUniqueReferenceNumber:
        initialTransactionDetails.customerUniqueReferenceNumber,
    };

    if (onVerifyTransaction.isLoading) return;

    onVerifyTransaction.mutate(body as any);
  };

  const handleCreateCardToken = async (verificationData: any) => {
    const body = {
      merchantUniqueReferenceNumber:
        verificationData.customerUniqueReferenceNumber,
      paymentGatewayReference: verificationData.paymentGatewayReferenceNumber,
      customerEmailAddress: userDetails.email,
      // gatewayCustomerId: verificationData.gatewayCustomerId ? verificationData.gatewayCustomerId : "none",
      newCustomer: true,
    };

    onCreateCartToken.mutate(body as any);

    // if (cardDetails) {
    //   onReplaceCartToken.mutate({
    //     uniqueReferenceNumber: cardDetails?.uniqueReferenceNumber,
    //     paymentGatewayReference: verificationData.paymentGatewayReferenceNumber,
    //     customerEmailAddress: userDetails.email,
    //     gatewayCustomerId: cardDetails.gatewayCustomerId,
    //     newCustomer: false,
    //   } as any);
    // } else {
    //   onCreateCartToken.mutate(body as any);
    // }
  };

  const handleStripePayment = () => {
    setOpenStripe(true);
    setOpenConfirmChange(false);
  };

  const handleAddCardSelection = () => {
    if (!gateWayDetails) {
      ToastMessage(TOASTER_TYPE.ERROR, "No gateway setting");
      return;
    }

    if (gateWayDetails.paymentGatewayType.toLowerCase() === "stripe") {
      handleStripePayment();
    }

    if (gateWayDetails.paymentGatewayType.toLowerCase() === "paystack") {
      handleAddPaystackCard();
    }
  };

  const handleRemoveCard = () => {
    onRemoveCartToken.mutate({
      GatewayCustomerId: cardDetails?.gatewayCustomerId,
      CustomerEmailAddress: cardDetails?.emailAddress,
      uniqueReferenceNumber: cardDetails?.uniqueReferenceNumber,
    } as any);
  };

  const gateWayDetails = gw_data && gw_data.data;

  console.log(cardDetails);

  return (
    <>
      {loadingPayment ||
        gw_loading ||
        onReplaceCartToken.isLoading ||
        onInitializeTransaction.isLoading ||
        ct_loading ||
        (onRemoveCartToken.isLoading && <Loader isFullPage={true} />)}
      <CardDetailsBox>
        {cardDetails && (
          <ul className="">
            <li>
              <div className="title">Card Number</div>
              <div className="details">
                **** **** ****{" "}
                {cardDetails.lastFourDigit || cardDetails?.last4Digit}
              </div>
            </li>
            <li>
              <div className="title">Holder’s Name</div>
              <div className="details">
                {cardDetails.lastFourDigit || cardDetails?.last4Digit}{" "}
              </div>
            </li>
            <li>
              <div className="title">Valid Till</div>
              <div className="details">
                {cardDetails.expiryMonth}/{cardDetails.expiryYear.slice(-2)}
              </div>
            </li>
          </ul>
        )}

        {/* {!cardDetails ? ( */}
        <div
          className="addPaymentContainer mb-0"
          onClick={() => {
            if (cardDetails) {
              handleRemoveCard();
            } else {
              handleAddCardSelection();
            }
          }}
        >
          {cardDetails ? (
            <div className="text">Remove card</div>
          ) : (
            <>
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.8125 5.875H8.375C8.02982 5.875 7.75 5.59518 7.75 5.25V1.8125C7.75 1.29473 7.33027 0.875 6.8125 0.875C6.29473 0.875 5.875 1.29473 5.875 1.8125V5.25C5.875 5.59518 5.59518 5.875 5.25 5.875H1.8125C1.29473 5.875 0.875 6.29473 0.875 6.8125C0.875 7.33027 1.29473 7.75 1.8125 7.75H5.25C5.59518 7.75 5.875 8.02982 5.875 8.375V11.8125C5.875 12.3303 6.29473 12.75 6.8125 12.75C7.33027 12.75 7.75 12.3303 7.75 11.8125V8.375C7.75 8.02982 8.02982 7.75 8.375 7.75H11.8125C12.3303 7.75 12.75 7.33027 12.75 6.8125C12.75 6.29473 12.3303 5.875 11.8125 5.875Z"
                  fill="#00AE97"
                />
              </svg>

              <div className="text">Add Payment Method</div>
            </>
          )}
        </div>
        {/* ) : (
          <></>
        )} */}
      </CardDetailsBox>

      {/* Confirm Add card */}
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        maxWidth={"md"}
        open={openConfirmChange}
        onClose={() => setOpenConfirmChange(false)}
        sx={{
          "& 	.MuiDialog-paperWidthMd": {
            width: "500px",
            // height: "750px",
            "@media (max-width: 567px)": {
              width: "90%",
              margin: "auto",
            },
          },
        }}
      >
        <ConfirmChangeCard
          handleAdd={handleAddCardSelection}
          handleClose={() => setOpenConfirmChange(false)}
        />
      </Dialog>

      {/* Stripe Modal */}
      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        maxWidth={"md"}
        open={openStripe}
        onClose={() => setOpenStripe(false)}
        sx={{
          "& 	.MuiDialog-paperWidthMd": {
            width: "600px",
            // height: "750px",
            "@media (max-width: 567px)": {
              width: "95%",
              margin: "auto",
            },
          },
        }}
      >
        <LoadStripe
          userDetails={userDetails}
          gateWayDetails={gateWayDetails}
          handleClose={() => setOpenStripe(false)}
          handleCreateCardToken={handleCreateCardToken}
        />
      </Dialog>

      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        maxWidth={"md"}
        open={openAddPaymentMethodSuccess}
        onClose={() => setOpenAddPaymentMethodSuccess(false)}
        sx={{
          "& 	.MuiDialog-paperWidthMd": {
            width: "500px",
            // height: "750px",
            "@media (max-width: 567px)": {
              width: "90%",
              margin: "auto",
            },
          },
        }}
      >
        <AddPaymentMethodSuccess />
      </Dialog>

      <Dialog
        disableEscapeKeyDown
        fullWidth={true}
        maxWidth={"md"}
        open={openPaystack}
        onClose={() => setOpenPaystack(false)}
        sx={{
          "& 	.MuiDialog-paperWidthMd": {
            width: "550px",
            height: "550px",
            "@media (max-width: 767px)": {
              // width: "92%",
              margin: "auto",
            },
          },
        }}
      >
        <iframe
          id="paystackFrame"
          width="100%"
          height="100%"
          src={`https://checkout.paystack.com/${initializeResp?.authorizationCode}`}
          frameBorder="0"
          sandbox="allow-scripts allow-popups allow-forms"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Paystack frame"
        />
      </Dialog>
    </>
  );
};

import { styled } from "@mui/material";

export const FooterBox = styled("footer")(({ theme }) => ({
  background: "#FFFDFD",
  padding: "6rem 0 0",
  fontFamily: "Raleway",

  "& .logo": {
    "& img": {
      width: "260px",
    },

    "& small": {
      fontFamily: "Raleway",

      "& a": {
        textTransform: "uppercase",
        textDecoration: "underline",
        color: "inherit",
      },
    },
  },

  "& .footerList": {
    listStyle: "none",
    margin: 0,
    padding: 0,
    display: "inline-flex",
    flexDirection: "column",
    gap: "1rem 0",

    "& .title": {
      color: "#FF5349",
      fontWeight: 500,
      fontSize: "1.25rem",
    },

    "& .item": {
      color: "#150200",
      fontSize: "1rem",
      fontWeight: 400,
      cursor: "pointer",

      "& a": {
        textDecoration: "none",
        color: "inherit",
      },
    },
  },

  "& .socialContainer": {
    marginTop: "3.5rem",

    "& .title": {
      color: "#454242",
      fontSize: "1.2rem",
      fontWeight: 400,
      marginBottom: "1rem",
    },

    "& .iconHolder": {
      display: "flex",
      gap: "0 1rem",
      alignItems: "center",

      "& a": {
        width: "25px",
        height: "25px",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        background: "#454242",
        color: "#ffffff",

        "& svg": {
          //   width: "18px",
          //   height: "18px",
        },
      },
    },
  },

  "& .legalSection": {
    margin: "3rem 0 0",
    padding: "1.5rem 0 3rem",
    borderTop: "1px solid rgba(132, 127, 127, 0.30)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",

    "& .legalLinks": {
      display: "flex",
      gap: "1rem",
    },

    "& .copyText, a": {
      fontSize: "0.875rem",
      fontWeight: 500,
      color: "#150200",
      textDecoration: "none",
    },
  },

  //   Styles for screen of width 991.9px and below
  "@media (max-width:991.9px)": {
    "& .logo": {
      "& img": {
        width: "190px",
      },
    },

    "& .footerList": {
      "& .title": {
        fontSize: "1.15rem",
      },

      "& .item": {
        fontSize: "0.95rem",
      },
    },

    "& .socialContainer": {
      marginTop: "3rem",

      "& .title": {
        fontSize: "1.15rem",
      },
    },
  },

  //   Styles for screen of width 991.9px and below
  "@media (max-width:767.9px)": {
    "& .logo": {
      "& img": {
        width: "170px",
      },

      "& small": {
        fontSize: "0.65rem",
      },
    },

    "& .footerList": {
      marginTop: "3rem",

      "& .title": {
        fontSize: "1rem",
      },

      "& .item": {
        fontSize: "0.75rem",
      },
    },

    "& .socialContainer": {
      marginTop: "3rem",

      "& .title": {
        fontSize: "1rem",
      },

      "& .iconHolder": {
        display: "flex",
        gap: "0 1rem",
        alignItems: "center",

        "& a": {
          width: "20px",
          height: "20px",

          "& svg": {
            width: "12px",
            height: "12px",
          },
        },
      },
    },

    "& .legalSection": {
      "& .copyText, a": {
        fontSize: "0.75rem",
      },
    },
  },
}));

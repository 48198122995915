import Logo from "../../../../assets/logo/logo-paycurra.png";
import { NavBarBox } from "./navbar.styles";
import { Link, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { FiMenu, FiX } from "react-icons/fi";
import * as React from "react";

const pages = [
  { link: "/features", name: "Features" },
  { link: "/pricing", name: "Pricing" },
  // { link: "/insights", name: "Insights" },
  { link: "/integration", name: "Integration" },
  // { link: "/paycurra", name: "Paycurra" },
];

export const Navbar = () => {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = React.useState(false);
  const paycurra_base_url = "https://www.paycurra.com";

  const handleRoute = (link: string, name: string) => {
    window.location.href = `${paycurra_base_url}${link}`;

    handleMenuToggle();
  };

  const handleMenuToggle = () => setOpenMenu(!openMenu);

  const handleRouteToContact = () => {
    window.location.href = `${paycurra_base_url}/book-a-demo`;
    handleMenuToggle();
  };

  return (
    <NavBarBox>
      {/* <NavBar /> */}
      <div className="container">
        <div className="d-flex align-items-center">
          <a href={paycurra_base_url} target={"_blank"}>
            <img src={Logo} alt="" width="80%" />
          </a>

          <IconButton
            className="d-lg-none ms-auto"
            size="large"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenuToggle}
          >
            <FiMenu />
          </IconButton>

          <div className={`menuContainer ${openMenu ? "visible" : "hidden"}`}>
            {openMenu && (
              <IconButton className="closeContainer" onClick={handleMenuToggle}>
                <FiX />
              </IconButton>
            )}

            <ul className="navMenu mx-auto">
              {pages.map((item, index) => (
                <li
                  key={`${item.name}-${index}`}
                  onClick={() => {
                    handleRoute(item.link, item.name);
                  }}
                >
                  {item.name}
                </li>
              ))}
            </ul>

            <button className="getBtn" onClick={handleRouteToContact}>
              Contact Sales
            </button>
          </div>
        </div>
      </div>
    </NavBarBox>
  );
};

import * as React from "react";
import { FrameBox } from "./CardFram.style";
import { AccordionSummary, AccordionDetails, Checkbox } from "@mui/material";
import { getCartToken } from "../../services/api";

interface IFrameProps {
  hasIcon?: boolean;
  icon?: React.SVGProps<SVGSVGElement>;
  title: string;
  subtitle?: string | JSX.Element;
  children?: JSX.Element | JSX.Element[];
  hasCheckbox?: boolean;
  handleCheck?: any;
  checkState?: boolean;
  isOrderSummary?: boolean;
}

export const CardFrame: React.FC<IFrameProps> = ({
  hasIcon = false,
  icon,
  title,
  subtitle,
  children,
  hasCheckbox = true,
  handleCheck,
  checkState,
  isOrderSummary = false,
}) => {
  const [expand, setExpand] = React.useState<boolean>(true);

  const toggleCardExpand = () => setExpand(!expand);

  return (
    <FrameBox expanded={expand} onChange={toggleCardExpand}>
      <div className="headerSection">
        {hasCheckbox && (
          <Checkbox
            className="checkbox"
            checked={checkState}
            onChange={handleCheck}
            inputProps={{ "aria-label": "controlled" }}
          />
        )}
        {/* {hasCheckbox && <input type={"checkbox"} />} */}
        {hasIcon && <> {icon} </>}

        <AccordionSummary
          expandIcon={
            <svg
              width="9"
              height="7"
              viewBox="0 0 9 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.73201 0.920905C4.13179 0.441523 4.86821 0.441523 5.26799 0.920904L8.7611 5.10953C9.30422 5.7608 8.84112 6.75 7.99311 6.75L1.00689 6.75C0.15888 6.75 -0.304218 5.7608 0.2389 5.10954L3.73201 0.920905Z"
                fill="#150200"
              />
            </svg>
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="headerContainer">
            <div className={`${!hasCheckbox ? "" : "ps-2"}`}>
              <div className="titleHolder">
                <h3 className="title">{title}</h3>
              </div>
              {subtitle && !isOrderSummary && (
                <p className="details">{subtitle}</p>
              )}
              {subtitle && isOrderSummary && (
                <div className="d-block w-100 mt-1 subTitleAmount">
                  <span>Subtotal</span>
                  <span>{subtitle}</span>
                </div>
              )}
            </div>
          </div>
        </AccordionSummary>
      </div>

      <AccordionDetails
        className={`${!hasIcon ? "detailWithIconMarginLeft" : ""}
        ${!hasCheckbox ? "detailNoMarginLeft" : ""}`}
      >
        {children}
      </AccordionDetails>
    </FrameBox>
  );
};

export const calculateTotalPrice = (
  totalAmount: any,
  pointValue: string,
  rewardDiscount: number = 0
) => {
  const total = Number(totalAmount);
  const point = Number(pointValue) * 100; // Assume point is 1naria and then convert to kobo

  const result = total - (isNaN(point) ? 0 : point) - rewardDiscount;

  if (result < 0) console.log(result);

  return result;
};

export const decodeToken = (token: string) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const earnableRewardInCartItem = (
  cartItems: any[],
  earnableReward: any[]
) => {
  let newCartItem: any[] = [];

  cartItems.forEach((cart: any) => {
    cart = {
      ...cart,
      rewardEffect: "",
      rewardType: "",
    };

    if (!earnableReward) {
      newCartItem.push(cart);
      return;
    }

    earnableReward.forEach((earnable: any) => {
      if (cart.sku === earnable.sku) {
        cart = {
          ...cart,
          rewardEffect: earnable.rewardEffect,
          rewardType: earnable.rewardType,
        };
      }
    });

    newCartItem.push(cart);
  });

  return newCartItem;
};

export const removeBase64StringImgFromCart = (cart: any[]) => {
  const formattedCart = cart.map((cartItem: any) => {
    return {
      name: cartItem.name,
      price: cartItem.price,
      quantity: cartItem.quantity,
      sku: cartItem.sku,
    };
  });

  return formattedCart;
};

export const convertFromNairaToKobo = (amount: number) => {
  return Number(amount) * 100;
};

/**
 * @param amount
 * @returns amount in naira
 * Note: convertPriceToNaira is only usee to over amount to naira for rendering on UI purpose only
 * Amount is used as Kobo in all calculation behind the scene
 */
export const convertPriceToNaira = (amount: number): number => {
  return Number(amount) / 100;
};

export const removeNullProperties = (obj: any) => {
  for (let key in obj) {
    if (obj[key] === null) {
      delete obj[key];
    }
  }
};
